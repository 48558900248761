import { useState, useRef } from "react";
import { Row, Col, Form } from "react-bootstrap";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";

const ApprovePage2 = (props) => {
  return (
    <>
      <h5 className="mb-3">Repayment Schedule</h5>
      <Form>
        <Row className="mb-5">
          <Form.Group controlId="numberOfInstalments">
            <Form.Label>Number of Installments</Form.Label>
            <Form.Control
              type="number"
              value={props.numberOfInstalments}
              onChange={(e) => {
                const newValue = Math.max(0, e.target.value);
                props.setNumberOfInstalments(newValue);
              }}
              min={0}
            />
          </Form.Group>
          {props.numberOfInstalments === "" && (
            <Form.Text className="text-danger">
              *Number of installments is required
            </Form.Text>
          )}
        </Row>
        <Row className="mb-5">
          <Col md={6}>
            <Form.Group controlId="startDate">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                value={props.startDate}
                onChange={(e) => {
                  props.setStartDate(e.target.value);
                }}
              />
            </Form.Group>
            {props.startDate === "" && (
              <Form.Text className="text-danger">
                *Start date is required
              </Form.Text>
            )}
          </Col>
          <Col md={6}>
            <Form.Group controlId="endDate">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                value={props.endDate}
                onChange={(e) => {
                  props.setEndDate(e.target.value);
                }}
              />
            </Form.Group>
            {props.endDate === "" && (
              <Form.Text className="text-danger">
                *End date is required
              </Form.Text>
            )}
          </Col>
        </Row>
        {props.isSecured ? (
          <Row className="mb-2">
            <Form.Group controlId="detail">
              <Form.Label>Details</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter any specific details regarding repayment structure."
                value={props.detail}
                onChange={(e) => {
                  props.setDetail(e.target.value);
                }}
              />
            </Form.Group>
          </Row>
        ) : null}
      </Form>
    </>
  );
};

export default ApprovePage2;
