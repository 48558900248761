import React, { useState, useEffect } from "react";
import FormHeader from "./FormHeader";
import FormBody from "./FormBody";
import classNames from "classnames";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import { Container, Spinner } from "react-bootstrap";

// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const ApplicationForm = () => {
  const [Token, setToken] = useState(null);
  const [loanApplication, setLoanApplication] = useState([]);
  const [client, setClient] = useState([]);
  const [clientCompany, setClientCompany] = useState([]);
  const [kyc, setKYC] = useState([]);
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    localStorage.setItem("token", token);
  }, [token]);

  useEffect(() => {
    const Token = Cookies.get("accessToken");
    setToken(Token);
    initializeForm();
  }, []);
  const [showSidebar, setShowSidebar] = useState(false);
  const initializeForm = async () => {
    try {
      const response = await fetch(
        `${backendUri}/api/client/get-application/${token}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
        }
      );

      const data = await response.json();
      const loanApplication = data.loanApplication;
      setLoanApplication(loanApplication);
      setClient(data.client);
     
      if (loanApplication.isPersonal == false) {
        setClientCompany(data.clientCompany);
      }
      setKYC(data.kyc);
      setLoading(false);
    } catch (err) {
      console.error("Error: ", err.message);
    }
  };
  return (
    <Container>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <Spinner animation="border" role="status" />
        </div>
      ) : (
        <div className="hk-pg-body py-0">
          <div className="contactapp-wrap loanapplicationform-wrap">
            <div className="loanapplicationform-content">
              <div className="contactapp-detail-wrap">
                <FormHeader />
                <FormBody
                  loanApplication={loanApplication}
                  client={client}
                  clientCompany={clientCompany}
                  kyc={kyc}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default ApplicationForm;
