import Cookies from "js-cookie";
import { toast } from "react-toastify";

// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;

const useLoanApplicationService = () => {
  const generateLoanApplicationLink = async (loanApplicationId) => {
    try {
      const response = await fetch(
        `${backendUri}/api/admin/get-application-link/${loanApplicationId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error getting loan application link:", error);
      return null; // or handle the error as needed
    }
  };

  const clientGenerateLoanApplicationLink = async (loanApplicationId) => {
    console.log("Halo World")
    try {
     
      const response = await fetch(
        `${backendUri}/api/client/get-application-link/${loanApplicationId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("accessToken")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error getting loan application link:", error);
      return null; // or handle the error as needed
    }
  };
  

  const loanApplicationStatusMapping = {
    "Pending - Loan Application Details": 0,
    "Pending - Personal Details": 1,
    "Pending - Employment Details": 2,
    "Pending - Important Documents": 3,
    "Pending - Disbursement Details": 4,
    "Pending - KYC Details": 5,
  
    "Pending - Download Loan Application": 6,
    "Pending - Sign Loan Application": 7,
  
    "Pending - Approve/Reject": 8,
  
    Rejected: 9,
    Approved: 10,
  
    "Pending - Download Loan Offer": 11,
    "Pending - Sign Loan Offer": 12,
    "Pending - Bank Receipt": 13,
  
    "Pending - Schedule Details": 14,
    "Pending - Download Schedule": 15,
    "Pending - Sign Schedule": 16,
  
    "Pending - Disbursement": 17,
    "Pending - Download Memo": 18,
    "Pending - Download Disbursement": 19,
    "Pending - Sign Memo": 20,
    "Pending - Sign Disbursement": 21,
    "Disbursed": 22,
  };

  const loanApplicationStatus = {
    PENDING_LOAN_APPLICATION_DETAILS: 0,
    PENDING_PERSONAL_DETAILS: 1,
    PENDING_EMPLOYMENT_DETAILS: 2,
    PENDING_IMPORTANT_DOCUMENTS: 3,
    PENDING_DISBURSEMENT_DETAILS: 4,
    PENDING_KYC_DETAILS: 5,
  
    PENDING_DOWNLOAD_LOAN_APPLICATION: 6,
    PENDING_SIGN_LOAN_APPLICATION: 7,
    PENDING_APPROVE_REJECT: 8,
  
    REJECTED: 9,
    APPROVED: 10,
  
    PENDING_DOWNLOAD_LOAN_OFFER: 11,
    PENDING_SIGN_LOAN_OFFER: 12,
    PENDING_BANK_RECEIPT: 13,
  
    PENDING_SCHEDULE_DETAILS: 14,
    PENDING_DOWNLOAD_SCHEDULE: 15,
    PENDING_SIGN_SCHEDULE: 16,
  
    PENDING_DISBURSEMENT: 17,
    PENDING_DOWNLOAD_MEMO: 18,
    PENDING_DOWNLOAD_DISBURSEMENT: 19,
    PENDING_SIGN_MEMO: 20,
    PENDING_SIGN_DISBURSEMENT: 21,
    DISBURSED: 22,
  };

  return {
    generateLoanApplicationLink,
    clientGenerateLoanApplicationLink,
    loanApplicationStatusMapping,
    loanApplicationStatus
  };
};

export default useLoanApplicationService;
