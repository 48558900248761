import React, { useEffect, useState } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Country } from "country-state-city";

const AddFormModal = (props) => {
  const [clientName, setclientName] = useState("");
  const [ICNumber, setICNumber] = useState("");
  const [companyRegistrationNo, setcompanyRegistrationNo] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [loanApplicationNumber, setLoanApplicationNumber] = useState("");
  const [nationality, setNationality] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formMode, setFormMode] = useState("personal");
  const [isPersonal, setIsPersonal] = useState(true);
  const [countries, setCountries] = useState([]);
  const [usePreviousInfo, setUsePreviousInfo] = useState(false); // New state

  const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSubmitting(true);

    await fetch(`${backendUri}/api/admin/create-application`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.accessToken}`,
      },
      body: JSON.stringify({
        clientName,
        ICNumber,
        companyName,
        companyRegistrationNo:
          isPersonal === true ? "None" : companyRegistrationNo,
        loanApplicationNumber,
        passportNumber,
        nationality,
        isPersonal,
        usePreviousInfo, // Send the state of previous info
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          toast.error(res.error, {
            containerId: "error-container",
          });
        } else {
          toast.success("Loan application created successfully.", {
            containerId: "success-container",
          });
          // Reset form fields
          setclientName("");
          setICNumber("");
          setcompanyRegistrationNo("");
          setCompanyName("");
          setLoanApplicationNumber("");
          setNationality("");
          setPassportNumber("");
          setUsePreviousInfo(false);
          setIsPersonal(true);
          setFormMode(isPersonal ? "personal" : "corporate");
          props.fetchData(props.accessToken);
          props.handleCloseForm();
        }
        setIsSubmitting(false);
      })
      .catch((err) => {
        console.error("Fetch API fail");
        setIsSubmitting(false);
      });
  };

  const handleLoanTypeChange = (event) => {
    setIsPersonal(event.target.value === "personal");
    setFormMode(event.target.value === "personal" ? "personal" : "corporate");
  };

  const handleCountryChange = (event) => {
    const newCountry = event.target.value;
    setNationality(newCountry);
  };

  useEffect(() => {
    const allCountries = Country.getAllCountries();
    setCountries(allCountries);
  }, []);

  return (
    <>
      <Modal show={props.showForm} onHide={props.handleCloseForm}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Loan Application Form</Modal.Title>
        </Modal.Header>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Modal.Body>
            <Row className="mb-2">
              <fieldset>
                <Form.Group controlId="formLoanType">
                  <Form.Label>Types of Loan : </Form.Label>
                  <Row>
                    <Col>
                      <Form.Check
                        type="radio"
                        label="Personal Loan"
                        value="personal"
                        name="loanType"
                        id="personalLoanRadios"
                        checked={isPersonal}
                        onChange={handleLoanTypeChange}
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        type="radio"
                        label="Corporate Loan"
                        value="corporate"
                        name="loanType"
                        id="corporateLoanRadios"
                        checked={!isPersonal}
                        onChange={handleLoanTypeChange}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </fieldset>
            </Row>

            <Row className="mb-2">
              <fieldset>
                <Form.Group controlId="formUsePreviousInfo">
                  <Form.Label>Do you want to take the previous info?</Form.Label>
                  <Row>
                    <Col>
                      <Form.Check
                        type="radio"
                        label="Yes"
                        value={true}
                        name="previousInfo"
                        id="previousInfoYes"
                        checked={usePreviousInfo === true}
                        onChange={() => setUsePreviousInfo(true)}
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        type="radio"
                        label="No"
                        value={false}
                        name="previousInfo"
                        id="previousInfoNo"
                        checked={usePreviousInfo === false}
                        onChange={() => setUsePreviousInfo(false)}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </fieldset>
            </Row>

            <Row className="mb-2">
              <Form.Group controlId="formName">
                <Form.Label>Client Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Name"
                  value={clientName}
                  onChange={(e) => {
                    setclientName(e.target.value);
                  }}
                />
              </Form.Group>
            </Row>

            <Row className="mb-2">
              <Form.Group>
                <Form.Label>Nationality</Form.Label>
                <Form.Select
                  name="nationality"
                  value={nationality}
                  onChange={handleCountryChange}
                  required
                >
                  <option value="">Choose...</option>
                  {countries.map((country) => (
                    <option key={country.name} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>

            {nationality !== "" ? (
              <Row className="mb-2">
                <Form.Group controlId="formIC">
                  <Form.Label>
                    {nationality === "Malaysian" || nationality === "Malaysia"
                      ? "IC Number (without '-')"
                      : "Passport Number"}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={
                      nationality === "Malaysian" || nationality === "Malaysia"
                        ? "Enter IC"
                        : "Enter Passport Number"
                    }
                    value={
                      nationality === "Malaysian" || nationality === "Malaysia"
                        ? ICNumber
                        : passportNumber
                    }
                    onChange={(e) => {
                      nationality === "Malaysian" || nationality === "Malaysia"
                        ? setICNumber(e.target.value)
                        : setPassportNumber(e.target.value);
                    }}
                    maxLength={nationality === "Malaysian" || nationality === "Malaysia" ? 12 : null}
                    minLength={nationality === "Malaysian" || nationality === "Malaysia" ? 12 : null}
                  />
                </Form.Group>
              </Row>
            ) : (
              ""
            )}

            {!isPersonal && (
              <>
                <Row className="mb-3">
                  <Form.Group controlId="formCompanyName">
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Company Name"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group controlId="formCompanyNo">
                    <Form.Label>Company Registration No</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Registration Number"
                      value={companyRegistrationNo}
                      onChange={(e) => setcompanyRegistrationNo(e.target.value)}
                    />
                  </Form.Group>
                </Row>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="soft-primary"
              type="submit"
              id="addSubmitBtn"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </Button>
            <Button variant="soft-secondary" onClick={props.handleCloseForm}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AddFormModal;
