import { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//require("dotenv").config();

// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const EditFormModal = (props) => {
  //is submiting
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [updateLoanApplicationData, setFormData] = useState("");

  useEffect(() => {
    if (props.showForm) {
      fetchLoanApplicationData(props.loanApplicationId);
    }
  }, [props.showForm]);

  const fetchLoanApplicationData = async (id) => {
    try {
      setFormData("");
      const response = await fetch(
        `${backendUri}/api/admin/get-application/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${props.accessToken}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setFormData({
          clientName: data.loanApplication.clientName || "",
          ICNumber: data.loanApplication.ICNumber || "",
          companyName: data.loanApplication.companyName || "None",
          companyRegistrationNo:
            data.loanApplication.companyRegistrationNo || "None",
          nationality: data.loanApplication.nationality || "",
          passportNumber: data.loanApplication.passportNumber || "",
        });
      } else {
        console.error("Fail to fetch loan application data");
      }
    } catch (error) {
      console.error("Error fetching loan application data : ", error.message);
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...updateLoanApplicationData,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdate = async () => {
    setIsSubmitting(true);
    await fetch(
      `${backendUri}/api/admin/update-application/${props.loanApplicationId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.accessToken}`,
        },
        body: JSON.stringify(updateLoanApplicationData),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          toast.error(` ${data.error}`, {
            containerId: "error-container",
          });
        } else {
          toast.success("Successful Update", {
            containerId: "success-container",
          });
          props.handleCloseForm();
        }

        props.fetchData(props.accessToken);
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.error("Error updating loan application:", error.message);
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Modal show={props.showForm} onHide={props.handleCloseForm}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Loan Application</Modal.Title>
        </Modal.Header>

        {updateLoanApplicationData ? (
          <>
            <Modal.Body>
              <Form>
                <Row className="mb-2">
                  <Form.Group controlId="formClientName">
                    <Form.Label>Client Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="clientName"
                      value={updateLoanApplicationData.clientName || ""}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group>
                    <Form.Label>Nationality</Form.Label>
                    <Form.Select
                      name="nationality"
                      value={updateLoanApplicationData.nationality}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                    >
                      <option value="Malaysian">Malaysian</option>
                      <option value="Non-Malaysian">Non-Malaysian</option>
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group controlId="formIc">
                    <Form.Label>
                      {updateLoanApplicationData.nationality === "Malaysian"
                        ? "IC Number (without '-')"
                        : "Passport Number"}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name={
                        updateLoanApplicationData.nationality === "Malaysian"
                          ? "ICNumber"
                          : "passportNumber"
                      }
                      value={
                        updateLoanApplicationData.nationality === "Malaysian"
                          ? updateLoanApplicationData.ICNumber
                          : updateLoanApplicationData.passportNumber
                      }
                      onChange={handleInputChange}
                      maxLength={updateLoanApplicationData.nationality === 'Malaysian' ? 12 : null}
                      minLength={updateLoanApplicationData.nationality === 'Malaysian' ? 12 : null}
                      required
                    />
                  </Form.Group>
                </Row>
                {updateLoanApplicationData.companyRegistrationNo !== "None" && (
                  <>
                    <Row className="mb-2">
                      <Form.Group controlId="formCompanyName">
                        <Form.Label> Company Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="companyName"
                          value={updateLoanApplicationData.companyName || ""}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-2">
                      <Form.Group controlId="formRegistroation">
                        <Form.Label> Company Registration Number</Form.Label>
                        <Form.Control
                          type="text"
                          name="companyRegistrationNo"
                          value={
                            updateLoanApplicationData.companyRegistrationNo ||
                            ""
                          }
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Row>
                  </>
                )}
                {/* <Row className="mb-2">
              <Form.Group controlId="formloanApplication">
                <Form.Label>Loan Application Number</Form.Label>
                <Form.Control
                  type="text"
                  name="loanApplicationNumber"
                  value={updateLoanApplicationData.loanApplicationNumber || ""}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Row> */}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="soft-primary"
                onClick={handleUpdate}
                id="updateSubmitBtn"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    {/* <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span> */}
                    <span>Updating...</span>
                  </>
                ) : (
                  "Update"
                )}
              </Button>
              <Button variant="soft-light" onClick={props.handleCloseForm}>
                Close
              </Button>
            </Modal.Footer>{" "}
          </>
        ) : (
          <div style={{ height: "150px" }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100px' }}>
              {" "}
              <Spinner size="sm" /> <span style={{marginLeft: "10px"}}>Fetching loan application</span>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default EditFormModal;
