import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { Plus, Edit2, Trash } from "react-feather";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  TabContent,
  Table,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import AddClientModal from "./AddClientModal";
import DeleteClientModal from "./DeleteClientModal";
import EditClientModal from "./EditClientModal";

// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;

const isAuthenticated = async () => {
  // Check if the access token is present
  const accessToken = Cookies.get("accessToken");

  if (!accessToken) {
    return false;
  }

  try {
    // Decode the token (optional, but you might want to access its payload)
    const decodedToken = jwtDecode(accessToken);

    // Check if the token is expired
    if (decodedToken.exp < Date.now() / 1000) {
      // Token is expired, remove it from cookies and return false
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      return false;
    } else {
      // Token is valid
      return true;
    }
  } catch (error) {
    // An error occurred while decoding the token (could be invalid)
    // Remove the token from cookies and return false
    Cookies.remove("accessToken");
    return false;
  }
};

const ClientView = (props) => {
  const history = useHistory();
  //accessToken variable
  const [Token, setToken] = useState(null);

  // Modal show variable
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);

  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  //Fetch data use
  const [lists, setList] = useState("");

  // ID get
  const [ClientId, setClientId] = useState(null);

  //Search Variable
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredList, setFilteredLists] = useState([...lists]);

  // Create new client
  const handleCreateClick = () => {
    setShowAddForm(true);
  };
  const handeCloseCreateForm = () => {
    setShowAddForm(false);
  };

  //Check authenticated and fetch all client`s data
  useEffect(() => {
    const checkAuthentication = async () => {
      if (!(await isAuthenticated())) {
        history.push("/auth/login-classic");
      }
    };
    checkAuthentication();

    const accessToken = Cookies.get("accessToken");
    setToken(accessToken);
    fetchData(accessToken);
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const filterItems = lists.filter(
        (item) =>
          item.clientName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (item.ICNumber &&
            item.ICNumber.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.mobileNumber &&
            item.mobileNumber
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.email &&
            item.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.passportNumber &&
            item.passportNumber
              .toLowerCase()
              .includes(searchTerm.toLowerCase()))
      );
      setFilteredLists(filterItems);
    } else {
      setFilteredLists([...lists]);
    }
  }, [lists, searchTerm]);

  //fetch all clients
  const fetchData = async (Token) => {
    await fetch(`${backendUri}/api/admin/clients`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.clients && data.clients.length > 0) {
          setList(data.clients);
        } else {
          setList([]);
        }
      })
      .catch((err) => {
        console.error("Login Failed due to :" + err.message);
      });
  };

  // Delete Client
  const handlDeleteClient = (id) => {
    setClientId(id);
    setShowDeleteForm(true);
  };
  const handeCloseDeleteModal = () => {
    setShowDeleteForm(false);
  };

  // Edit Client
  const handleClientEdit = (id) => {
    setClientId(id);
    setShowEditForm(true);
  };
  const handleCloseEditModal = () => {
    setShowEditForm(false);
  };

  //Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  //Update the render to use filteredLists
  const currentItems = filteredList.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <Container>
        <TabContent>
          <Card className="card-border mb-0 h-100">
            <Card.Header className="card-header-action">
              <h5> CLIENT MANAGEMENT </h5>
              <div className="card-action-wrap">
                <Button
                  variant="primary btn-animated"
                  size="sm"
                  className="ms-3"
                  id="addClient"
                  onClick={handleCreateClick}
                >
                  <span>
                    <span className="icon">
                      <span className="feather-icon">
                        <Plus />
                      </span>
                    </span>
                    <span className="btn-text " size="lg">
                      Add new
                    </span>
                  </span>
                </Button>
              </div>
            </Card.Header>
            {lists ? (
              <Card.Body>
                <InputGroup className="mb-4">
                  <Form.Control
                    className="table-search"
                    type="text"
                    placeholder="Search..."
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </InputGroup>
                {Array.isArray(currentItems) && currentItems.length > 0 ? (
                  <div className="invoice-list-view">
                    <div className="table-responsive">
                      <Table responsive striped hover className="mb-0">
                        <thead>
                          <tr>
                            <th>
                              <span className="d-flex">
                                <span className="flex-grow-1">Client Name</span>
                              </span>
                            </th>
                            <th>
                              <span className="d-flex">
                                <span className="flex-grow-1">
                                  IC Number/Passport Number
                                </span>
                              </span>
                            </th>
                            <th>
                              <span className="d-flex">
                                <span className="flex-grow-1">
                                  Mobile Number
                                </span>
                              </span>
                            </th>
                            <th>
                              <span className="d-flex">
                                <span className="flex-grow-1">Email</span>
                              </span>
                            </th>
                            <th>
                              <span className="d-flex">
                                <span className="flex-grow-1">Action</span>
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody id="dataTable">
                          {currentItems.map((item) => (
                            <tr key={item._id}>
                              <td>{item.clientName}</td>
                              <td>
                                {item.ICNumber
                                  ? item.ICNumber
                                  : item.passportNumber}
                              </td>
                              <td>{item.mobileNumber}</td>
                              <td>{item.email}</td>
                              <td>
                                <Button
                                  variant="flush-dark"
                                  className="btn-icon"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Edit"
                                  id="editClient"
                                  onClick={() => handleClientEdit(item._id)}
                                >
                                  <span className="edit">
                                    <span className="feather-icon">
                                      <Edit2 />
                                    </span>
                                  </span>
                                </Button>

                                <Button
                                  variant="flush-danger"
                                  className="btn-icon"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Delete"
                                  id="deleteClient"
                                  onClick={() => handlDeleteClient(item._id)}
                                >
                                  <span className="delete">
                                    <span className="feather-icon">
                                      <Trash />
                                    </span>
                                  </span>
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <Row>
                        <Col
                          md={{ span: 2, offset: 10 }}
                          xs={{ span: 12, offset: 0 }}
                          className="text-center mt-3"
                        >
                          <Button
                            variant="primary btn-animated"
                            className="btn-icon"
                            disabled={currentPage === 1}
                            onClick={() => paginate(currentPage - 1)}
                          >
                            <span>
                              <i className="ri-arrow-left-s-line" />
                            </span>
                          </Button>
                          <span className="mx-1">{currentPage}</span>
                          <Button
                            variant="primary btn-animated "
                            className="btn-icon"
                            disabled={indexOfLastItem >= lists.length}
                            onClick={() => paginate(currentPage + 1)}
                          >
                            <span>
                              <i className="ri-arrow-right-s-line" />
                            </span>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center text-center"
                    style={{ height: "200px" }}
                  >
                    <p>No client at the moment</p>
                  </div>
                )}
              </Card.Body>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center text-center"
                style={{ height: "200px" }}
              >
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {"\u00A0\u00A0\u00A0"}Fetching clients data...
                </>
              </div>
            )}
          </Card>
        </TabContent>
      </Container>

      <ToastContainer
        position="top-right"
        style={{ top: "70px", right: "20px" }}
        enableMultiContainer
        containerId="success-container"
      />
      <AddClientModal
        showForm={showAddForm}
        handleCloseForm={handeCloseCreateForm}
        accessToken={Token}
        fetchData={fetchData}
      />
      <DeleteClientModal
        showForm={showDeleteForm}
        handleCloseForm={handeCloseDeleteModal}
        accessToken={Token}
        clientId={ClientId}
        fetchData={fetchData}
      />
      <EditClientModal
        showForm={showEditForm}
        handleCloseForm={handleCloseEditModal}
        accessToken={Token}
        clientId={ClientId}
        fetchData={fetchData}
      />
    </>
  );
};
export default ClientView;
