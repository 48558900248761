import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
//require("dotenv").config();

// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;

const DeleteFormModal = (props) => {
  //is submiting
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleDelete = async () => {
    setIsSubmitting(true);
    await fetch(
      `${backendUri}/api/admin/delete-or-restore-application/${props.loanApplicationId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.accessToken}`,
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          props.fetchData(props.accessToken);
          toast.error(`Fail to ${props.showDeleted ? "Restore" : "Delete"}`, {
            containerId: "success-container",
          });
          props.handleCloseForm();
          setIsSubmitting(false);
        } else {
          props.fetchData(props.accessToken);
          toast.success(data.message, {
            containerId: "success-container",
          });
          props.handleCloseForm();
          setIsSubmitting(false);
        }
      })
      .catch((err) => {
        console.error("Error deleting item", err.message);
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Modal show={props.showForm} onHide={props.handleCloseForm}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm {props.showDeleted ? "Restore" : "Deletion"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to {props.showDeleted ? "restore" : "delete"} this data?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="soft-light" onClick={props.handleCloseForm}>
            Close
          </Button>
          <Button
            variant="soft-danger"
            onClick={handleDelete}
            id="deleteSubmitBtn"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                {/* <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span> */}
                <span>{props.showDeleted ? "Restoring..." : "Deleting..."}</span>
              </>
            ) : (
              `Confirm ${props.showDeleted ? "Restore" : "Delete"}`
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteFormModal;
