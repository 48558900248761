import React, { useState } from "react";
import { Modal, Form, Button, Row, Col, InputGroup } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NumericFormat } from "react-number-format";

const SecondReminderModal = (props) => {
  const [oweDate, setOweDate] = useState("");
  const [totalInstallment, setTotalInstallment] = useState("");
  const [outstandingBalance, setOutstandingBalance] = useState("");
  const [newDueDate, setNewDueDate] = useState("");
  const [repaymentDay, setRepaymentDay] = useState("");

  const today = new Date().toISOString().split("T")[0];
  //require("dotenv").config();

  // eslint-disable-next-line no-undef
  const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
  const handleCreate = () => {
    const oweDateEpoch = Date.parse(oweDate);
    const newDueDateEpoch = Date.parse(newDueDate);

    fetch(
      `${backendUri}/api/admin/second-reminder/${props.loanApplicationId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.accessToken}`,
        },
        body: JSON.stringify({
          outstandingBalance,
          totalInstallment,
          oweDate: `${oweDateEpoch}`,
          newDueDate: `${newDueDateEpoch}`,
          repaymentDay,
        }),
      }
    )
      .then(async (res) => {
        if (!res.ok) {
          return res.json().then((errorData) => {
            throw new Error(errorData.error);
          });
        }
        const contentDisposition = res.headers.get("Content-Disposition");
        const filename = contentDisposition
          .split("filename=")[1]
          .split(";")[0]
          .replace(/"/g, "");
        // Read the response as a blob
        const blob = await res.blob();

        // Create a blob URL
        const url = window.URL.createObjectURL(new Blob([blob]));

        // Create a link element
        const link = document.createElement("a");
        link.href = url;

        // Set the download attribute and trigger a click
        link.setAttribute("download", `${filename}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Clean up and update data
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        toast.error(error.message, {
          containerId: "error-container",
        });
      });
  };
  return (
    <>
      <Modal show={props.showForm} onHide={props.handleCloseForm} size="lg">
        <Modal.Header closeButton>
          <Modal.Title> Second Reminder Creation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form style={{ margin: 10 }}>
            <Row className="mb-4 mt-2">
              <Col md={5}>
                <h6 style={{ margin: 3 }}>Outstanding Balance</h6>
              </Col>
              <Col md={1}>:</Col>
              <Col md={6}>
                <Form.Group controlId="outstandingBalance">
                  <InputGroup>
                    <InputGroup.Text>RM</InputGroup.Text>
                    <NumericFormat
                      aria-label="Outstanding Balance"
                      name="outstandingBalance"
                      thousandSeparator={true}
                      value={outstandingBalance || ""}
                      onValueChange={(values) => {
                        const { value } = values;
                        setOutstandingBalance(value);
                      }}
                      customInput={Form.Control}
                    />
              
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-4 mt-2">
              <Col md={5}>
                <h6 style={{ margin: 3 }}>Total Instalment</h6>
              </Col>
              <Col md={1}>:</Col>
              <Col md={6}>
                <Form.Group controlId="totalInstalment">
                  <InputGroup>
                    <InputGroup.Text>RM</InputGroup.Text>
                    <NumericFormat
                      aria-label="Total Instalment"
                      name="totalInstalment"
                      thousandSeparator={true}
                      value={totalInstallment || ""}
                      onValueChange={(values) => {
                        const { value } = values;
                        setTotalInstallment(value);
                      }}
                      customInput={Form.Control}
                    />
               
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={5}>
                <h6 style={{ margin: 3 }}>Owe Date </h6>
              </Col>
              <Col md={1}>:</Col>
              <Col md={6}>
                <Form.Group controlId="oweDate">
                  <Form.Control
                    type="date"
                    value={oweDate}
                    onChange={(e) => {
                      setOweDate(e.target.value);
                    }}
                    max={today}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={5}>
                <h6 style={{ margin: 3 }}>New Due Date </h6>
              </Col>
              <Col md={1}>:</Col>
              <Col md={6}>
                <Form.Group controlId="newDueDate">
                  <Form.Control
                    type="date"
                    value={newDueDate}
                    onChange={(e) => {
                      setNewDueDate(e.target.value);
                    }}
                    min={today}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={5}>
                <h6 style={{ margin: 3 }}>Repayment Day </h6>
              </Col>
              <Col md={1}>:</Col>
              <Col md={6}>
                <Form.Group controlId="repaymentDay">
                  <Form.Control
                    type="number"
                    value={repaymentDay}
                    onChange={(e) => {
                      setRepaymentDay(e.target.value);
                    }}
                    min={today}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="soft-primary"
            onClick={handleCreate}
            id="reminderCreateBtn"
          >
            Create
          </Button>
          <Button variant="soft-secondary" onClick={props.handleCloseForm}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SecondReminderModal;
