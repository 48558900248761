import { useState, useEffect } from "react";
import { Modal, Form, Button, Row, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Country, State } from "country-state-city";
// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;

const EditClientModal = (props) => {
  const [updateClient, setFormData] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [nationality, setNationality] = useState("");
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const allCountries = Country.getAllCountries();
    setCountries(allCountries);
  }, []);
  useEffect(() => {
    if (props.showForm) {
      fetchClientData(props.clientId);
    }
  }, [props.showForm]);

  const fetchClientData = async (id) => {
    try {
      setFormData("");
      const response = await fetch(`${backendUri}/api/admin/clients/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.accessToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setFormData({
          clientName: data.clientName || "",
          ICNumber: data.ICNumber || "",
          mobileNumber: data.mobileNumber || "",
          email: data.email || "",
          nationality: data.nationality || "",
          passportNumber: data.passportNumber || "",
        });
      } else {
        console.error("Fail to fetch client`s data");
      }
    } catch (error) {
      console.error("Error fetching client`s data : ", error.message);
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...updateClient,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdate = async () => {
    setIsSubmitting(true);
    await fetch(`${backendUri}/api/admin/clients/${props.clientId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.accessToken}`,
      },
      body: JSON.stringify(updateClient),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.error("Failed to update loan application:", data.error);
          toast.error(` ${data.message}`, {
            containerId: "error-container",
          });
        } else {
          toast.success(data.message, {
            containerId: "success-container",
          });
          props.handleCloseForm();
        }

        props.fetchData(props.accessToken);
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.error("Error updating loan application:", error.message);
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Modal show={props.showForm} onHide={props.handleCloseForm}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Client Information</Modal.Title>
        </Modal.Header>
        {updateClient ? (
          <>
            {" "}
            <Modal.Body>
              <Form>
                <Row className="mb-2">
                  <Form.Group controlId="editName">
                    <Form.Label>Client Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="clientName"
                      value={updateClient.clientName || ""}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group controlId="editNationality">
                    <Form.Label>Nationality</Form.Label>
                    <Form.Select
                      id="editNationality"
                      name="nationality"
                      value={updateClient.nationality}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                    >
                      <option value="Malaysian" disabled hidden>Malaysian</option>
                      <option value="Non-Malaysian" disabled hidden>Non-Malaysian</option>
                      {countries.map((country) => (
                        <option key={country.name} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group controlId="formIc">
                    <Form.Label>
                      {updateClient.nationality === "Malaysian" || updateClient.nationality === "Malaysia"
                        ? "IC Number (without '-')"
                        : "Passport Number"}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name={
                        updateClient.nationality === "Malaysian" || updateClient.nationality === "Malaysia"
                          ? "ICNumber"
                          : "passportNumber"
                      }
                      value={
                        updateClient.nationality === "Malaysian" || updateClient.nationality === "Malaysia"
                          ? updateClient.ICNumber
                          : updateClient.passportNumber
                      }
                      maxLength={
                        updateClient.nationality === "Malaysian" || updateClient.nationality === "Malaysia" ? 12 : null
                      }
                      minLength={
                        updateClient.nationality === "Malaysian" || updateClient.nationality === "Malaysia" ? 12 : null
                      }
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group controlId="editPhoneNumber">
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="mobileNumber"
                      value={updateClient.mobileNumber || ""}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group controlId="editEmail">
                    <Form.Label>Client Email</Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      value={updateClient.email || ""}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              {" "}
              <Button
                variant="soft-primary"
                onClick={handleUpdate}
                id="editSubmitBtn"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    {/* <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span> */}
                    <span>Updating...</span>
                  </>
                ) : (
                  "Update"
                )}
              </Button>
              <Button
                variant="soft-light"
                onClick={props.handleCloseForm}
                id="editCloseBtn"
              >
                Close
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <div style={{ height: "150px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100px",
              }}
            >
              {" "}
              <Spinner size="sm" />{" "}
              <span style={{ marginLeft: "10px" }}>Fetching client data</span>
            </div>
          </div>
        )}

        <ToastContainer
          position="top-right"
          style={{ top: "70px", right: "20px" }}
          enableMultiContainer
          containerId="error-container"
        />
      </Modal>
    </>
  );
};

export default EditClientModal;
