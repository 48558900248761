import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import NubraProgress from "../../components/@hk-progressbar/HkProgressBar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SectionA from "./SA_LoanAppDetails";
import SectionB from "./SB_PersonalDetails";
import SectionC from "./SC_EmploymentDetails";
import SectionD from "./SD_ImpDocuments";
import SectionE from "./SE_DisbursementDetails";
import SectionF from "./SF_KYC";
import moment from "moment";

// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;

const FormBody = ({ loanApplication, client, clientCompany, kyc }) => {
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [loanDetails, setLoanDetails] = useState({});
  const [status, setStatus] = useState();
  const [companyDetails, setCompanyDetails] = useState({});
  const [clientDetails, setClientDetails] = useState({});
  const [KYCDetails, setKYCDetails] = useState({});
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const isPersonalLoan = loanApplication.isPersonal;
  const ID = loanApplication._id;
  const [mobilePhoneMask, setMobilePhoneMask] = useState("");
  const [homeNumberMask, setHomeNumberMask] = useState("");
  const [officePhoneMask, setOfficePhoneMask] = useState("");
  const [faxMask, setFaxMask] = useState("");
  const [isinvalid, setIsInvalid] = useState(false);

  const statusArray = [
    "Pending - Loan Application Details",
    "Pending - Personal Details",
    "Pending - Employment Details",
    "Pending - Important Documents",
    "Pending - Disbursement Details",
    "Pending - KYC Details",
    "Pending - Download Loan Application",
    "Pending - Sign Loan Application",
    "Pending - Approve / Reject",
    "Rejected",
    "Approved",
    "Pending - Download Loan Offer",
    "Pending - Sign Loan Offer",
    "Pending - Bank Receipt",
    "Pending - Schedule Details",
    "Pending - Download Schedule",
    "Pending - Sign Schedule",
    "Pending - Disbursement",
    "Pending - Download Memo",
    "Pending - Download Disbursement",
    "Pending - Sign Memo",
    "Pending - Sign Disbursement",
    "Disbursed",
  ];

  useEffect(() => {
    delete loanApplication.__v;
    setLoanDetails(loanApplication);
  }, [loanApplication]);

  useEffect(() => {
    if (loanDetails.status) {
      setStatus(statusArray.indexOf(loanDetails.status));
    }
  }, [loanDetails]);

  useEffect(()=>{
    console.log(status)

  }, [status])


  useEffect(() => {
    delete clientCompany.__v;
    setCompanyDetails(clientCompany);
    setCompanyDetails((prevClientCompany) => {
      if (!prevClientCompany.companyCountry && !isPersonalLoan) {
        return {
          ...prevClientCompany,
          companyCountry: "MY",
        };
      }
      return prevClientCompany;
    });
  }, [clientCompany, isPersonalLoan]);

  useEffect(() => {
    delete client.__v;
    let formattedDateOfBirth;
    // Format date using Moment.js

    if (client.dateOfBirth) {
      formattedDateOfBirth = moment(client.dateOfBirth).format("YYYY-MM-DD");
      // Set the formatted date back to the client object
      client.dateOfBirth = formattedDateOfBirth;
    }

    setClientDetails(client);
    setClientDetails((prevClient) => {
      const updatedClient = { ...prevClient };
      if (!updatedClient.country) {
        updatedClient.country = "MY";
      }

      if (!updatedClient.officeCountry) {
        updatedClient.officeCountry = "MY";
      }
      return updatedClient;
    });
  }, [client]);

  useEffect(() => {
    delete kyc.__v;
    setKYCDetails(kyc);
  }, [kyc]);

  const handleNext = async (event) => {
    let apiEndpoint;
    let dataToSend;
    let convertedKYCDetails;
    const formData = new FormData();
    setLoading(true);
    switch (step) {
      case 1:
        apiEndpoint = `${backendUri}/api/client/update-application-details/${ID}`;
        loanDetails.purpose = loanDetails.otherPurpose
          ? loanDetails.otherPurpose
          : loanDetails.purpose;
        loanDetails.tenure = loanDetails.otherTenure
          ? loanDetails.otherTenure
          : loanDetails.tenure;
        dataToSend = {
          loanApplication: { ...loanDetails },
          clientCompany: { ...companyDetails },
        };
        break;
      case 2:
        apiEndpoint = `${backendUri}/api/client/update-client/${ID}`;
        clientDetails.race = clientDetails.otherRace
          ? clientDetails.otherRace
          : clientDetails.race;
        dataToSend = {
          client: { ...clientDetails },
        };
        break;
      case 3:
        apiEndpoint = `${backendUri}/api/client/update-employment-details/${ID}`;
        clientDetails.annualOtherIncomeType =
          clientDetails.otherClientOtherIncomeType
            ? clientDetails.otherClientOtherIncomeType
            : clientDetails.annualOtherIncomeType;
        dataToSend = {
          client: { ...clientDetails },
        };
        break;
      case 4:
        apiEndpoint = `${backendUri}/api/client/confirm-upload-required-files/${ID}`;
        dataToSend = {
          loanApplication: { ...loanDetails },
        };
        break;
      case 5:
        apiEndpoint = `${backendUri}/api/client/update-disbursement-details/${ID}`;
        loanDetails.bankName = loanDetails.otherBankName
          ? loanDetails.otherBankName
          : loanDetails.bankName;
        dataToSend = {
          loanApplication: { ...loanDetails },
        };
        break;
      case 6:
        apiEndpoint = `${backendUri}/api/client/update-kyc-details/${ID}`;
        convertedKYCDetails = Object.fromEntries(
          Object.entries(KYCDetails).map(([key, value]) => [
            key,
            value === "true" ? true : value === "false" ? false : value,
          ])
        );
        dataToSend = {
          kyc: { ...convertedKYCDetails },
        };
        break;
      default:
        break;
    }

    if (apiEndpoint) {
      try {
        const response = await fetch(apiEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToSend),
        });

        const responseData = await response.json();

        if (responseData.error) {
          toast.error(` ${responseData.error}`, {
            containerId: "error-container",
          });
          setLoading(false);
        } else {
          setLoading(false);
          // Only update the step if there is no error in the response
          if (step < 6) {
            setStep(step + 1);
          } else {
            history.push("/clients/success");
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handlePrevious = () => {
    setLoading(false);
    setStep(step - 1);
  };

  const getPhoneMask = (value, type) => {
    const maskMapping = {
      "+60 11": "+60 99-9999 9999",
      "+60 1": "+60 99-999 9999",
      "+60 3": "+60 9-9999 9999",
      "+60 8": "+60 99-999 999",
      default: "+60 9-999 9999",
    };

    const prefix = Object.keys(maskMapping).find((key) =>
      value.startsWith(key)
    );
    return maskMapping[prefix] || maskMapping.default;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    const maskTypeMapping = {
      mobileNumber: "mobile",
      homeNumber: "home",
      officeTelNumber: "office",
      officeFaxNumber: "fax",
    };

    const maskSetterMapping = {
      mobileNumber: setMobilePhoneMask,
      homeNumber: setHomeNumberMask,
      officeTelNumber: setOfficePhoneMask,
      officeFaxNumber: setFaxMask,
    };

    if (name in maskTypeMapping) {
      const maskType = maskTypeMapping[name];
      const setMask = maskSetterMapping[name];

      setMask(getPhoneMask(value, maskType));
    }

    setLoanDetails({ ...loanDetails, [name]: value });
    setCompanyDetails({ ...companyDetails, [name]: value });
    setClientDetails({ ...clientDetails, [name]: value });
    setKYCDetails({ ...KYCDetails, [name]: value });
  };

  const handleFileInputChange = (event) => {
    const { name, value } = event.target;
    const isMultipleFiles = name === "others";
    const newFiles = event.target.files;

    let formData = new FormData();

    if (isMultipleFiles) {
      Array.from(newFiles).forEach((file, index) => {
        formData.append(`files`, file);
      });
    } else {
      formData.append("file", newFiles[0]);
    }

    setFile(newFiles);
    const uploadFile = async () => {
      const apiEndpoint = `${backendUri}/api/client/upload-file/${name}/${ID}`;

      try {
        const response = await fetch(apiEndpoint, {
          method: "POST",
          body: formData,
        });

        const responseData = await response.json();

        if (responseData.error) {
          toast.error(` ${responseData.error}`, {
            containerId: "error-container",
          });
        }

        const uploadedFiles = isMultipleFiles
          ? responseData.serverFileNames
          : responseData.serverFileName;
      
        setLoanDetails({
          ...loanDetails,
          [name]: uploadedFiles,
        });
      } catch (error) {
        console.error("File upload failed:", error);
      }
    };

    uploadFile();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const isPhoneNumberValid = (phoneNumbers) => {
    return phoneNumbers.every((number) => {
      return !number || (!number.includes("_") && number.trim() !== "");
    });
  };

  const handleValidation = () => {
    let requiredFields = [];
    let phoneNumbersToCheck = [];
    let isValid;

    switch (step) {
      case 1:
        if (loanDetails.isPersonal === true) {
          requiredFields = [
            loanDetails.isSecured,
            loanDetails.loanAmount,
            loanDetails.tenure,
            loanDetails.purpose,
          ];
        } else {
          requiredFields = [
            companyDetails.companyName,
            companyDetails.companyAddressLine1,
            companyDetails.companyCity,
            companyDetails.companyZipCode,
            companyDetails.companyState,
            loanDetails.isSecured,
            loanDetails.loanAmount,
            loanDetails.tenure,
            loanDetails.purpose,
          ];
        }
        break;
      case 2:
        requiredFields = [
          clientDetails.email,
          clientDetails.gender,
          clientDetails.dateOfBirth,
          clientDetails.race,
          clientDetails.maritalStatus,
          clientDetails.dependentsNumber,
          clientDetails.mobileNumber,
          clientDetails.addressLine1,
          clientDetails.city,
          clientDetails.zipCode,
          clientDetails.state,
        ];

        phoneNumbersToCheck = [
          clientDetails.mobileNumber,
          clientDetails.homeNumber,
        ];

        if (!isPhoneNumberValid(phoneNumbersToCheck)) {
          setIsInvalid(true);
          return;
        }
        break;
      case 3:
        requiredFields = [
          clientDetails.employmentType,
          clientDetails.employerName,
          clientDetails.natureOfBusiness,
          clientDetails.designation,
          clientDetails.lengthOfService,
          clientDetails.annualGrossIncomeAmount,
          clientDetails.annualGrossIncomeType,
          clientDetails.officeAddressLine1,
          clientDetails.officeCity,
          clientDetails.officeZipCode,
          clientDetails.officeState,
        ];

        phoneNumbersToCheck = [
          clientDetails.officeTelNumber,
          clientDetails.officeFaxNumber,
        ];

        if (!isPhoneNumberValid(phoneNumbersToCheck)) {
          setIsInvalid(true);
          return;
        }
        break;
      case 4:
        if (loanDetails.applicantType == "Salaried Applicants") {
          requiredFields = [
            loanDetails.photocopyOfIC,
            loanDetails.epfStatement,
            loanDetails.allApplicantBankStatements,
            loanDetails.applicantType,
            loanDetails.salaryConfirmationLetter,
            loanDetails.salarySlip,
          ];
        } else {
          requiredFields = [
            loanDetails.photocopyOfIC,
            loanDetails.epfStatement,
            loanDetails.allApplicantBankStatements,
            loanDetails.applicantType,
            loanDetails.ssm,
            loanDetails.profitAndLossStatement,
            loanDetails.selfEmployedBankStatement,
          ];
        }
        break;
      case 5:
        requiredFields = [
          loanDetails.bankName,
          loanDetails.purpose,
          loanDetails.accountType,
          loanDetails.accountHolderName,
          loanDetails.accountNumber,
        ];
        break;
      case 6:
        requiredFields = [
          KYCDetails.q1,
          KYCDetails.q2,
          KYCDetails.q3,
          KYCDetails.q4,
          KYCDetails.q5,
          KYCDetails.q6,
        ];
        break;
      default:
        break;
    }
    isValid = requiredFields.every(
      (field) => field !== null && field !== undefined && field !== ""
    );
    setIsInvalid(!isValid);
    if (isValid) {
      handleNext();
    }
  };

  return (
    <div className="contact-body">
      <SimpleBar className="nicescroll-bar">
        <div className="contact-list-view">
          <Row className="mb-3">
            <Col md={12}>
              <Form onSubmit={handleSubmit}>
                <NubraProgress
                  variant="primary"
                  now={(step / 6) * 100}
                  className="mb-5"
                  rounded
                />
                {step === 1 && (
                  <SectionA
                    isPersonalLoan={isPersonalLoan}
                    handleInputChange={handleInputChange}
                    loanDetails={loanDetails}
                    companyDetails={companyDetails}
                    isinvalid={isinvalid}
                    status={status}
                  />
                )}
                {step === 2 && (
                  <SectionB
                    handleInputChange={handleInputChange}
                    clientDetails={clientDetails}
                    mobilePhoneMask={mobilePhoneMask}
                    homeNumberMask={homeNumberMask}
                    isinvalid={isinvalid}
                    status={status}
                  />
                )}
                {step === 3 && (
                  <SectionC
                    handleInputChange={handleInputChange}
                    clientDetails={clientDetails}
                    isinvalid={isinvalid}
                    officePhoneMask={officePhoneMask}
                    faxMask={faxMask}
                    status={status}
                  />
                )}
                {step === 4 && (
                  <SectionD
                    handleFileInputChange={handleFileInputChange}
                    handleInputChange={handleInputChange}
                    loanDetails={loanDetails}
                    ID={ID}
                    isinvalid={isinvalid}
                    status={status}
                  />
                )}
                {step === 5 && (
                  <SectionE
                    handleInputChange={handleInputChange}
                    loanDetails={loanDetails}
                    isinvalid={isinvalid}
                    status={status}
                  />
                )}
                {step === 6 && (
                  <SectionF
                    handleInputChange={handleInputChange}
                    KYCDetails={KYCDetails}
                    isinvalid={isinvalid}
                    status={status}
                  />
                )}
                <div className="button-group">
                  {step > 1 && (
                    <Button
                      id="prevBtn"
                      variant="primary"
                      size="sm"
                      className="mx-2"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                  )}
                  {step < 6 ? (
                    <Button
                      id="nextBtn"
                      variant="primary"
                      size="sm"
                      onClick={handleValidation}
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  ) : (
                    <Button
                      id="submitBtn"
                      variant="primary"
                      size="sm"
                      onClick={handleValidation}
                      disabled={loading || status === null || status === undefined || (status && parseInt(status) >= 9)}
                    >
                      {loading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  )}
                </div>
              </Form>
              <ToastContainer
                position="top-right"
                style={{ top: "70px", right: "20px" }}
                enableMultiContainer
                containerId="error-container"
              />
            </Col>
          </Row>
        </div>
      </SimpleBar>
    </div>
  );
};

export default FormBody;
