import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";


// Image
import SuccessImg from "../../assets/dist/img/form-submission-success.png";

const SuccessPage = (props) => {
  const handleReturnToDashboard = () => {
    window.location.href = "/";
  };

  const handleModifySubmission = () => {
    const token = localStorage.getItem("token");
    console.log(token);
    window.location.href = `/clients/application_form/${token}`;
  };
  return (
    <>
      <div className="hk-pg-wrapper">
        <div className="hk-pg-body">
          <Container>
            <Row>
              <Col xl={7} lg={6} sm={12} className="d-block">
                <div className="auth-content py-md-0 py-8">
                  <Row>
                    <Col xl={12} className="text-center">
                      <img
                        src={SuccessImg}
                        className="img-fluid w-sm-70 w-50"
                        alt="login"
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xl={5} lg={6} md={12} sm={12} className="d-flex align-items-center">
                <div className="auth-content py-md-0 py-8">
                  <div className="w-100">
                    <Row>
                      <Col className="text-center text-lg-start">
                        <h1 id="titleSuccess" className="display-4 fw-bold mb-2">Thank You for Completing the Form</h1>
                        <p>
                            Kindly contact the admin if there's any details you would like to ammend. 
                        </p>
                        <div className="mt-4">
                          <Button
                            variant="primary"
                            className="me-3"
                            onClick={handleReturnToDashboard}
                          >
                            Back to Home
                          </Button>
                          <Button
                            variant="secondary"
                            onClick={handleModifySubmission}
                          >
                            Edit Submission
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* Page Footer */}
      </div>
    </>
  );
};

export default SuccessPage;
