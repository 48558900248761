import React, { useEffect, useState } from "react";
import { Modal, Form, Button, InputGroup, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
//require("dotenv").config();

// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const DisbursementModal = (props) => {
  const [loanApplicationData, setLoanApplicationData] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state
  const acceptedFileTypes = ".pdf, .txt, .png, .jpg, .jpeg, .gif, .docx";
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  useEffect(() => {
    if (props.showForm && props.loanApplicationId) {
      fetchLoanApplicationData(props.loanApplicationId);
    }
  }, [props.showForm, props.loanApplicationId]);

  const fetchLoanApplicationData = async (id) => {
    try {
      const response = await fetch(`${backendUri}/api/admin/disburse/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.accessToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();

        setLoanApplicationData(data.disbursement.signCopyLink || null);
      } else {
        console.error("Fail to fetch loan application data");
      }
    } catch (error) {
      console.error("Error fetching loan application data : ", error.message);
    }
  };

  const handleUpload = () => {
    if (!file) {
      toast.error("Please choose a file to upload", {
        containerId: "error-container",
      });
      return;
    }
    setLoading(true); // Set loading to true
    let formData = new FormData();
    formData.append("file", file);
    fetch(
      `${backendUri}/api/admin/upload/signed/disbursement/${props.loanApplicationId}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${props.accessToken}`,
        },
        body: formData,
      }
    )
      .then((res) => {
        if (!res.ok) {
          return res.json().then((errorData) => {
            throw new Error(errorData.error);
          });
        }
        return res.json();
      })
      .then((data) => {
        if (data) {
          toast.success(data.message, {
            containerId: "success-container",
          });
          props.fetchData(props.accessToken);
          props.handleCloseForm();
        } else {
          toast.error("File upload failed", {
            containerId: "error-container",
          });
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error(`${error}`, {
          containerId: "error-container",
        });
      })
      .finally(() => {
        setLoading(false); // Reset loading after upload
      });
  };

  const handleDownload = () => {
    fetch(
      `${backendUri}/api/admin/download/signed/disbursement/${props.loanApplicationId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
          Authorization: `Bearer ${props.accessToken}`,
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${loanApplicationData}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        console.error("API fetch fail", err);
      });
  };

  return (
    <Modal show={props.showForm} onHide={props.handleCloseForm}>
      <Modal.Header closeButton>
        <Modal.Title>Disbursement Upload</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="uploadedFile" className="mb-1">
          <Form.Label>Please submit your disbursement at here</Form.Label>
          <InputGroup>
            <Form.Control
              type="file"
              name="disbursement"
              onChange={handleFileChange}
              accept={acceptedFileTypes}
              required
            />
          </InputGroup>
        </Form.Group>
        {loanApplicationData ? (
          <p>
            Uploaded File:
            <span> </span>
            <a href="#" onClick={handleDownload} id="uploadedSignFile">
              {loanApplicationData}
            </a>
          </p>
        ) : (
          <p>Uploaded File: </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="soft-primary"
          id="uploadSubmitBtn"
          onClick={handleUpload}
          disabled={loading} // Disable button during loading
        >
          {loading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Uploading...
            </>
          ) : (
            "Upload"
          )}
        </Button>
        <Button
          variant="soft-light"
          id="closeBtn"
          onClick={props.handleCloseForm}
          disabled={loading} // Disable button during loading
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DisbursementModal;
