import { useState } from "react";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { NumericFormat } from "react-number-format";
const ApprovePage1 = (props) => {
  return (
    <>
      <h5 className="mb-3">Offer Letter Details</h5>
      <Form>
        <Row className="mb-5">
          <Form.Group controlId="loanAmount">
            <Form.Label>Loan Amount</Form.Label>
            <InputGroup>
              <InputGroup.Text>RM</InputGroup.Text>
              <NumericFormat
                aria-label="Loan Amount Approved"
                name="loanAmount"
                thousandSeparator={true}
                value={props.loanAmountApproved || ""}
                onValueChange={(values) => {
                  const { value } = values;
                  props.setLoanAmountApproved(value);
                }}
                customInput={Form.Control}
              />
            </InputGroup>
          </Form.Group>
          {/* Conditionally render the message */}
          {props.loanAmountApproved === "" && (
            <Form.Text className="text-danger">
              *Loan amount approved is required
            </Form.Text>
          )}
        </Row>
        <Row className="mb-5">
          <Col md={6}>
            <Form.Group controlId="interestRate">
              <Form.Label>Interest Rate (Per Annum)</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  value={props.interestRate}
                  onChange={(e) => {
                    const newValue = Math.max(0, e.target.value);
                    props.setInterestRate(newValue);
                  }}
                  min={0}
                />
                <InputGroup.Text>%</InputGroup.Text>
              </InputGroup>
            </Form.Group>
            {props.interestRate === "" && (
              <Form.Text className="text-danger">
                *Interest rate is required
              </Form.Text>
            )}
          </Col>

          <Col>
            <Form.Group>
              <Form.Label>Loan Term</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  value={props.loanTermValue}
                  onChange={(e) => {
                    const newValue = Math.max(0, e.target.value);
                    props.setLoanTermValue(newValue);
                  }}
                  min={0}
                  id="loanTerm"
                />
                <Form.Select
                  value={props.loanTerm}
                  onChange={(e) => {
                    props.setLoanTerm(e.target.value);
                  }}
                  id="loanPeriod"
                >
                  <option value="">Choose...</option>
                  <option value="months">Months</option>
                  <option value="years">Years</option>
                </Form.Select>
              </InputGroup>
            </Form.Group>
            {(props.loanTerm === "" || props.loanTermValue === "") && (
              <Form.Text className="text-danger">
                *Loan term and value is required
              </Form.Text>
            )}
            {/* <Form.Group controlId="loanTerm">
                <Form.Label>Loan Term</Form.Label>
                <Form.Select
                  value={props.loanTerm}
                  onChange={(e) => {
                    props.setLoanTerm(e.target.value);
                  }}
                >
                  <option value="choose">Choose...</option>
                  <option value="months">Months</option>
                  <option value="years">Years</option>
                </Form.Select>
              </Form.Group> */}
          </Col>
        </Row>

        {props.isSecured ? (
          <>
            <Row className="mb-5">
              <Form.Group as={Col} controlId="security">
                <Form.Label>Security / Collateral</Form.Label>
                <Form.Control
                  type="text"
                  value={props.security}
                  onChange={(e) => {
                    props.setSecurity(e.target.value);
                  }}
                />
              </Form.Group>
              {props.security === "" && (
                <Form.Text className="text-danger">
                  *Security is required
                </Form.Text>
              )}
            </Row>
            <Row className="mb-5">
              <Form.Group controlId="securityValue">
                <Form.Label>Security / Collateral Value</Form.Label>
                <InputGroup>
                  <InputGroup.Text>RM</InputGroup.Text>
                  <NumericFormat
                    aria-label="Security Value"
                    name="securityValue"
                    thousandSeparator={true}
                    value={props.securityValue || ""}
                    onValueChange={(values) => {
                      const { value } = values;
                      props.setSecurityValue(value);
                    }}
                    customInput={Form.Control}
                  />
                </InputGroup>
              </Form.Group>
              {props.securityValue === "" && (
                <Form.Text className="text-danger">
                  *Security value is required
                </Form.Text>
              )}
            </Row>
          </>
        ) : null}
        <Row className="mb-2">
          <Form.Group controlId="disburseBefore">
            <Form.Label>Disburse Before</Form.Label>
            <Form.Control
              type="date"
              value={props.disburseBefore}
              onChange={(e) => {
                props.setDisburseBefore(e.target.value);
              }}
            />
          </Form.Group>
          {props.disburseBefore === "" && (
            <Form.Text className="text-danger">
              *Disburse before value is required
            </Form.Text>
          )}
        </Row>
      </Form>
    </>
  );
};

export default ApprovePage1;
