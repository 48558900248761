import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
//require("dotenv").config();

// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const RejectModal = (props) => {
  const [status, setStatus] = useState("Rejected");

  const handleConfirmReject = () => {
    fetch(
      `${backendUri}/api/admin/loan-application/rejected/${props.loanApplicationId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.accessToken}`,
        },
        body: JSON.stringify({
          status,
        }),
      }
    )
      .then((res) => {
        if (!res.ok) {
          return res.json().then((errorData) => {
            throw new Error(errorData.error);
          });
        }
        return res.json();
      })
      .then((data) => {
        toast.success(data.message, { containerId: "success-container" });
        props.fetchData(props.accessToken);

        props.handleCloseForm();
      })
      .catch((error) => {
        console.log(error.message);
        toast.error(`${error.message}`, {
          containerId: "error-container",
        });
      });
  };

  return (
    <>
      <Modal show={props.showForm} onHide={props.handleCloseForm}>
        <Modal.Header closeButton>
          <Modal.Title> Rejection Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to reject this loan application?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="soft-light" onClick={props.handleCloseForm}>
            Close
          </Button>
          <Button
            variant="soft-danger"
            onClick={handleConfirmReject}
            id="confirmRejectBtn"
          >
            Comfirm Reject
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RejectModal;
