import React, { useState, useEffect } from "react";
import { Col, Form, Row, InputGroup } from "react-bootstrap";
import { Country, State } from "country-state-city";
import { NumericFormat } from "react-number-format";
import InputMask from "react-input-mask";

const SectionC = ({
  handleInputChange,
  clientDetails,
  isinvalid,
  officePhoneMask,
  faxMask,
  status
}) => {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [otherClientOtherIncomeType, setOtherClientOtherIncomeType] =
    useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  useEffect(() => {
    setSelectedCountry(clientDetails.officeCountry);
  }, [clientDetails.officeCountry]);

  useEffect(() => {
    const allCountries = Country.getAllCountries();
    setCountries(allCountries);
    if (!clientDetails.officeCountry) {
      const malaysia = allCountries.find(
        (country) => country.name === "Malaysia"
      );
      if (malaysia) {
        setSelectedCountry(malaysia.isoCode);
      }
    }
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      const statesForCountry = State.getStatesOfCountry(selectedCountry);
      setStates(statesForCountry);
      setSelectedState("");
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (clientDetails.annualOtherIncomeType === undefined) {
      setOtherClientOtherIncomeType("");
    } else if (
      !["Rental (Stamped Tenancy Agreement)", ""].includes(
        clientDetails.annualOtherIncomeType
      )
    ) {
      setOtherClientOtherIncomeType("Others");
    } else {
      setOtherClientOtherIncomeType(clientDetails.annualOtherIncomeType);
    }
  }, [clientDetails.annualOtherIncomeType]);

  const handleCountryChange = (event) => {
    const newCountry = event.target.value;
    setSelectedCountry(newCountry);
    handleInputChange(event);
  };

  const handleStateChange = (event) => {
    const newState = event.target.value;
    setSelectedState(newState);
    handleInputChange(event);
  };
  return (
    <Form.Group controlId="formStep3">
      <h1 id="titleSC" className="pg-title mb-4">
        Employment Details
      </h1>
      <Row className="mb-3">
        <Form.Group
          as={Col}
          controlId="clientEmploymentType"
          sm={12}
          md={6}
          className="mb-3 mb-md-0"
        >
          <Form.Label>Employment Type*</Form.Label>
          <Form.Select
            name="employmentType"
            value={clientDetails.employmentType}
            onChange={handleInputChange}
            isInvalid={isinvalid && !clientDetails.employmentType}
            disabled={status === null || status === undefined || (status && parseInt(status) >= 9)}
          >
            <option value="">Choose...</option>
            <option value="Self-Employed with Worker">
              Self-Employed with Worker
            </option>
            <option value="Government Employee/Civil Servant">
              Government Employee/Civil Servant
            </option>
            <option value="Self-Employed without Worker">
              Self-Employed without Worker
            </option>
            <option value="Private Sector Employee">
              Private Sector Employee
            </option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please select an employment type.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="clientEmployerName"
          sm={12}
          md={6}
          className="mb-md-0"
        >
          <Form.Label>Employer's Name*</Form.Label>
          <Form.Control
            name="employerName"
            value={clientDetails.employerName || ""}
            onChange={handleInputChange}
            isInvalid={isinvalid && !clientDetails.employerName}
            disabled={status === null || status === undefined || (status && parseInt(status) >= 9)}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a name.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group
          as={Col}
          controlId="clientNatureOfBusiness"
          sm={12}
          md={4}
          className="mb-3 mb-md-0"
        >
          <Form.Label>Nature Of Business*</Form.Label>
          <Form.Control
            name="natureOfBusiness"
            value={clientDetails.natureOfBusiness || ""}
            onChange={handleInputChange}
            isInvalid={isinvalid && !clientDetails.natureOfBusiness}
            disabled={status === null || status === undefined || (status && parseInt(status) >= 9)}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a nature of business.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="clientDesignation"
          sm={12}
          md={4}
          className="mb-3 mb-md-0"
        >
          <Form.Label>Designation*</Form.Label>
          <Form.Control
            name="designation"
            value={clientDetails.designation || ""}
            onChange={handleInputChange}
            isInvalid={isinvalid && !clientDetails.designation}
            disabled={status === null || status === undefined || (status && parseInt(status) >= 9)}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a designation.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="clientLengthOfService"
          sm={12}
          md={4}
          className="mb-md-0"
        >
          <Form.Label>Length of Service*</Form.Label>
          <Form.Control
            name="lengthOfService"
            value={clientDetails.lengthOfService || ""}
            onChange={handleInputChange}
            isInvalid={isinvalid && !clientDetails.lengthOfService}
            disabled={status === null || status === undefined || (status && parseInt(status) >= 9)}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a value.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group
          as={Col}
          controlId="clientGrossIncome"
          sm={12}
          md={3}
          className="mb-3 mb-md-0"
        >
          <Form.Label>Annual Gross Income*</Form.Label>

          <InputGroup>
            <InputGroup.Text>RM</InputGroup.Text>
            <NumericFormat
              aria-label="Annual Gross Income"
              name="annualGrossIncomeAmount"
              thousandSeparator={true}
              value={clientDetails.annualGrossIncomeAmount || ""}
              onValueChange={(values) => {
                const { value } = values;
                handleInputChange({
                  target: {
                    name: "annualGrossIncomeAmount",
                    value: value,
                  },
                });
              }}
              customInput={Form.Control}
              allowNegative={false}
              isInvalid={isinvalid && !clientDetails.annualGrossIncomeAmount}
              disabled={status === null || status === undefined || (status && parseInt(status) >= 9)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a value.
            </Form.Control.Feedback>

            {/* <Form.Control
              type="number"
              min="0"
              name="annualGrossIncomeAmount"
              defaultValue={clientDetails.annualGrossIncomeAmount || ""}
              onChange={handleInputChange}
            /> */}
          </InputGroup>
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="clientGrossIncomeType"
          sm={12}
          md={3}
          className="mb-3 mb-md-0"
        >
          <Form.Label>Annual Gross Income Type*</Form.Label>
          <Form.Select
            name="annualGrossIncomeType"
            value={clientDetails.annualGrossIncomeType}
            onChange={handleInputChange}
            isInvalid={isinvalid && !clientDetails.annualGrossIncomeType}
            disabled={status === null || status === undefined || (status && parseInt(status) >= 9)}
          >
            <option value="">Choose...</option>
            <option value="Salary">Salary</option>
            <option value="Commission">Commission</option>
            <option value="Business">Business</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please select an income type.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="clientOtherIncome"
          sm={12}
          md={3}
          className="mb-3 mb-md-0"
        >
          <Form.Label>Annual Other Income</Form.Label>
          <InputGroup>
            <InputGroup.Text>RM</InputGroup.Text>
            <NumericFormat
              aria-label="Annual Other Income"
              name="annualOtherIncomeAmount"
              thousandSeparator={true}
              value={clientDetails.annualOtherIncomeAmount || ""}
              onValueChange={(values) => {
                const { value } = values;
                handleInputChange({
                  target: {
                    name: "annualOtherIncomeAmount",
                    value: value,
                  },
                });
              }}
              customInput={Form.Control}
              allowNegative={false}
              disabled={status === null || status === undefined || (status && parseInt(status) >= 9)}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="clientOtherIncomeType"
          sm={12}
          md={3}
          className="mb-md-0"
        >
          <Form.Label>Annual Other Income Type</Form.Label>
          <Form.Select
            name="annualOtherIncomeType"
            value={otherClientOtherIncomeType}
            onChange={handleInputChange}
            disabled={status === null || status === undefined || (status && parseInt(status) >= 9)}
          >
            <option value="">Choose...</option>
            <option value="Rental (Stamped Tenancy Agreement)">
              Rental (Stamped Tenancy Agreement)
            </option>
            <option value="Others">Others</option>
          </Form.Select>
        </Form.Group>
        {otherClientOtherIncomeType === "Others" && (
          <Form.Group
            as={Col}
            controlId="otherClientOtherIncomeType"
            sm={12}
            md={3}
            className="mt-3 mt-md-3"
          >
            <Form.Label>Specify Other Income Type</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter other Income Type"
              name="otherClientOtherIncomeType"
              defaultValue={clientDetails.annualOtherIncomeType || ""}
              onChange={handleInputChange}
              disabled={status === null || status === undefined || (status && parseInt(status) >= 9)}
            />
          </Form.Group>
        )}
      </Row>
      <Row className="mb-3">
        <Form.Group
          as={Col}
          controlId="officeTelNumber"
          sm={12}
          md={6}
          className="mb-3 mb-md-0"
        >
          <Form.Label>Office Tel. No.</Form.Label>
          <InputMask
            className={`form-control ${
              clientDetails.officeTelNumber &&
              clientDetails.officeTelNumber.includes("_")
                ? "is-invalid"
                : ""
            }`}
            name="officeTelNumber"
            mask={officePhoneMask}
            value={clientDetails.officeTelNumber || ""}
            onChange={handleInputChange}
            disabled={status === null || status === undefined || (status && parseInt(status) >= 9)}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid office number.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="officeFaxNumber"
          sm={12}
          md={6}
          className="mb-3 mb-md-0"
        >
          <Form.Label>Office Fax No.</Form.Label>
          <InputMask
            className={`form-control ${
              clientDetails.officeFaxNumber &&
              clientDetails.officeFaxNumber.includes("_")
                ? "is-invalid"
                : ""
            }`}
            name="officeFaxNumber"
            mask={faxMask}
            value={clientDetails.officeFaxNumber || ""}
            onChange={handleInputChange}
            disabled={status === null || status === undefined || (status && parseInt(status) >= 9)}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid fax number.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <h5>Office Address</h5>
      <Form.Group className="mb-3" controlId="officeAddressLine1">
        <Form.Label>Address Line 1*</Form.Label>
        <Form.Control
          placeholder="1234 Main St"
          name="officeAddressLine1"
          value={clientDetails.officeAddressLine1 || ""}
          onChange={handleInputChange}
          isInvalid={isinvalid && !clientDetails.officeAddressLine1}
          disabled={status === null || status === undefined || (status && parseInt(status) >= 9)}
        />
        <Form.Control.Feedback type="invalid">
          Please provide an address.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="officeAddressLine2">
        <Form.Label>Address Line 2</Form.Label>
        <Form.Control
          placeholder="Apartment, studio, or floor"
          name="officeAddressLine2"
          defaultValue={clientDetails.officeAddressLine2 || ""}
          onChange={handleInputChange}
          disabled={status === null || status === undefined || (status && parseInt(status) >= 9)}
        />
      </Form.Group>

      <Row className="mb-3">
        <Form.Group
          as={Col}
          controlId="officeCity"
          sm={12}
          md={3}
          className="mb-3 mb-md-0"
        >
          <Form.Label>Area*</Form.Label>
          <Form.Control
            name="officeCity"
            onChange={handleInputChange}
            value={clientDetails.officeCity || ""}
            isInvalid={isinvalid && !clientDetails.officeCity}
            disabled={status === null || status === undefined || (status && parseInt(status) >= 9)}
          />
          <Form.Control.Feedback type="invalid">
            Please provide an area.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="officeZipCode"
          sm={12}
          md={3}
          className="mb-3 mb-md-0"
        >
          <Form.Label>Postal Code*</Form.Label>
          <NumericFormat
            name="officeZipCode"
            value={clientDetails.officeZipCode || ""}
            onValueChange={(values) => {
              const { value } = values;
              handleInputChange({
                target: {
                  name: "officeZipCode",
                  value: value,
                },
              });
            }}
            customInput={Form.Control}
            allowNegative={false}
            decimalScale={0}
            isInvalid={isinvalid && !clientDetails.officeZipCode}
            disabled={status === null || status === undefined || (status && parseInt(status) >= 9)}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a postal code.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="officeState"
          sm={12}
          md={3}
          className="mb-3 mb-md-0"
        >
          <Form.Label>State*</Form.Label>
          <Form.Select
            name="officeState"
            onChange={handleStateChange}
            value={clientDetails.officeState}
            isInvalid={isinvalid && !clientDetails.officeState}
            disabled={status === null || status === undefined || (status && parseInt(status) >= 9)}
          >
            <option value="">Choose...</option>
            {states.map((state) => (
              <option key={state.isoCode} value={state.isoCode}>
                {state.name}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please select a state.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="officeCountry"
          sm={12}
          md={3}
          className="mb-3 mb-md-0"
        >
          <Form.Label>Country*</Form.Label>
          <Form.Select
            name="officeCountry"
            onChange={handleCountryChange}
            value={clientDetails.officeCountry || selectedCountry}
            disabled
          >
            <option value="">Choose...</option>
            {countries.map((country) => (
              <option key={country.isoCode} value={country.isoCode}>
                {country.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Row>
    </Form.Group>
  );
};

export default SectionC;
