import React, { useState } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;

const AddCompanyModal = (props) => {
  const [companyName, setCompanyName] = useState("");
  const [companyRegistrationNo, setCompanyRegistrationNo] = useState("");
  //is submiting
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    await fetch(`${backendUri}/api/admin/company`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.accessToken}`,
      },
      body: JSON.stringify({
        companyName,
        companyRegistrationNo,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.error) {
          toast.error(res.error, {
            containerId: "error-container",
          });
        } else {
          toast.success(res.message, {
            containerId: "success-container",
          });

          // Reset form fields
          setCompanyName("");
          setCompanyRegistrationNo("");

          props.fetchData(props.accessToken);
          props.handleCloseForm();
        }
        setIsSubmitting(false);
      })
      .catch((err) => {
        console.error("Fetch API  fail");
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Modal show={props.showForm} onHide={props.handleCloseForm}>
        <Modal.Header closeButton>
          <Modal.Title> Create New Client Company </Modal.Title>
        </Modal.Header>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Modal.Body>
            <Row className="mb-2">
              <Form.Group controlId="addCompanyName">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Company Name"
                  value={companyName}
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                  }}
                />
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group controlId="addCompanyRegistrationNumber">
                <Form.Label>Company Registration Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Company Registration Number "
                  value={companyRegistrationNo}
                  onChange={(e) => {
                    setCompanyRegistrationNo(e.target.value);
                  }}
                />
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="soft-primary"
              type="submit"
              id="addSubmitBtn"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  {/* <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span> */}
                  <span>Submitting...</span>
                </>
              ) : (
                "Submit"
              )}
            </Button>
            <Button
              variant="soft-secondary"
              onClick={props.handleCloseForm}
              id="closeCreateFormBtn"
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
        <ToastContainer
          position="top-right"
          style={{ top: "70px", right: "20px" }}
          enableMultiContainer
          containerId="error-container"
        />
      </Modal>
    </>
  );
};

export default AddCompanyModal;
