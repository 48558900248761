import { Modal, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { useState, useEffect } from "react";
// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const DeleteCompanyModal = (props) => {
  //is submiting
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleDelete = async () => {
    setIsSubmitting(true);
    await fetch(`${backendUri}/api/admin/company/${props.companyId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.accessToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          toast.error(data.error, {
            containerId: "error-container",
          });
        } else {
          props.fetchData(props.accessToken);
          toast.success(data.message, {
            containerId: "success-container",
          });
        }

        props.handleCloseForm();
        setIsSubmitting(false);
      })
      .catch((err) => {
        console.error("Error deleting item", err.message);
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Modal show={props.showForm} onHide={props.handleCloseForm}>
        <Modal.Header closeButton>
          <Modal.Title>Comfirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this data?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="soft-light"
            onClick={props.handleCloseForm}
            id="closeDeleteModalBtn"
          >
            Close
          </Button>
          <Button
            variant="soft-danger"
            onClick={handleDelete}
            id="confirmDeleteBtn"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              "Deleting..."
            ) : (
              " Confirm Delete"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-right"
        style={{ top: "70px", right: "20px" }}
        enableMultiContainer
        containerId="error-container"
      />
    </>
  );
};

export default DeleteCompanyModal;
