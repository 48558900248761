import { useRef, useEffect } from "react";
import {
  Modal,
  Form,
  Button,
  ModalHeader,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Copy } from "react-feather";
//require("dotenv").config();

// eslint-disable-next-line no-undef
const frontendUri = process.env.REACT_APP_FRONTEND_DOMAIN;
const LinkModalForm = (props) => {
  const linkRef = useRef(null);

  const handleCopyLink = () => {
    if (linkRef.current) {
      navigator.clipboard
        .writeText(linkRef.current.href)
        .then(() => {
          console.log("Link copied to clipboard!");
        })
        .catch((err) => {
          console.error("Unable to copy link to clipboard", err);
        });
    }
  };

  return (
    <Modal show={props.showForm} onHide={props.handleCloseForm}>
      <ModalHeader closeButton>
        <Modal.Title>Client Data Link</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="tooltip">{props.LinkGenerated}</Tooltip>}
        >
          <Link
            to={`/clients/application_form/${props.LinkGenerated}`}
            ref={linkRef}
            id="link"
            target="_blank"
          >
            {frontendUri}/clients/application_form/{props.LinkGenerated}
          </Link>
        </OverlayTrigger>

        <Button
          variant="flush-dark"
          className="btn-icon "
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          id="copyButton"
          onClick={handleCopyLink}
        >
          <span className="edit">
            <span className="feather-icon">
              <Copy />
            </span>
          </span>
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleCloseForm}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LinkModalForm;
