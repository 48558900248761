import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ExternalLink } from "react-feather";
import { Link } from "react-router-dom";

const PageFooter = () => {
  return (
    <div className="hk-footer">
      <Container as="footer" className="footer">
        <Row>
        </Row>
      </Container>
    </div>
  );
};

export default PageFooter;
