import { AnimatePresence } from "framer-motion";
import React, { Suspense } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import PageAnimate from "../components/Animation/PageAnimate";
import { clientRoutes } from "./RouteList";

const ClassicRoutes = (props) => {
  const { match } = props;

  return (
    <>
      <AnimatePresence>
        <Suspense
          fallback={
            <div className="preloader-it">
              <div className="loader-pendulums" />
            </div>
          }
        >
          <Switch>
            {clientRoutes.map((obj, i) => {
              return obj.component ? (
                <Route
                  key={i}
                  exact={obj.exact}
                  path={match.path + obj.path}
                  render={(matchProps) => (
                    <>
                      <PageAnimate>
                        <obj.component {...matchProps} />
                      </PageAnimate>
                    </>
                  )}
                />
              ) : null;
            })}
            <Route path="*">
              <Redirect to="/error-404" />
            </Route>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </>
  );
};

export default ClassicRoutes;
