import { useState, useEffect } from "react";
import { Row, Col, Form, Alert } from "react-bootstrap";
import { NumericFormat } from "react-number-format";

const ApprovePage3 = (props) => {
  useEffect(() => {
    calculateSetupFee();
    //10 is copy fee
    const stampingFeeCalculate = (props.loanAmountApproved / 1000) * 5 + 10;
    props.setStampingFee(Number(stampingFeeCalculate.toFixed(2)));
  }, []);

  useEffect(() => {
    const totalCalculate = (
      props.setupFee +
      props.legalDisbursementFee +
      props.stampingFee +
      props.miscellaneousCharges
    ).toFixed(2);
    props.setTotalFee(totalCalculate);
  }, [
    props.setupFee,
    props.loanAmountApproved,
    props.legalDisbursementFee,
    props.stampingFee,
    props.miscellaneousCharges,
  ]);

  const handleSetupFeeChange = (value) => {
    props.setSetupFee(Number(value));
    // calculateTotalFee();
  };

  const handleLegalDisbursementFeeChange = (value) => {
    props.setLegalDisbursementFee(Number(value));
    //calculateTotalFee();
  };

  const handleStampingFeeChange = (value) => {
    props.setStampingFee(Number(value));
    // calculateTotalFee();
  };

  const handleMiscellaneousChargesChange = (value) => {
    props.setMiscellaneousCharges(Number(value));
    // calculateTotalFee();
  };

  const calculateSetupFee = () => {
    if (props.loanAmountApproved >= 3000 && props.loanAmountApproved < 10000) {
      props.setSetupFee(50);
    } else if (
      props.loanAmountApproved >= 10000 &&
      props.loanAmountApproved < 20000
    ) {
      props.setSetupFee(100);
    } else if (
      props.loanAmountApproved >= 20000 &&
      props.loanAmountApproved < 50000
    ) {
      props.setSetupFee(150);
    } else if (
      props.loanAmountApproved >= 50000 &&
      props.loanAmountApproved < 100000
    ) {
      props.setSetupFee(200);
    } else if (
      props.loanAmountApproved >= 100000 &&
      props.loanAmountApproved < 500000
    ) {
      props.setSetupFee(250);
    } else if (props.loanAmountApproved >= 500000) {
      props.setSetupFee(300);
    }
  };

  const calculateTotalFee = () => {
    //setStampingFee(stampingFeeCalculate);
    const totalCalculate =
      props.setupFee +
      props.legalDisbursementFee +
      props.stampingFee +
      props.miscellaneousCharges;
    props.setTotalFee(totalCalculate);
  };

  return (
    <>
      <h5 className="mb-3">Loan Appliction Charges</h5>

      <Row className="mb-2">
        <Col md={8}>Setup Fees</Col>
        <Col md={1}>RM</Col>
        <Col>
          <Form.Group controlId="setupFee">
            <NumericFormat
              aria-label="Setup Fees"
              name="setupFees"
              size="sm" // Set size to "sm"
              thousandSeparator={true}
              value={props.setupFee || ""}
              onValueChange={(values) => {
                const { value } = values;
                handleSetupFeeChange(value);
              }}
              customInput={Form.Control}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={8}>Legal & Disbursement Fees</Col>
        <Col md={1}>RM</Col>
        <Col>
          <Form.Group controlId="legalDisbursementFee">
            <NumericFormat
              aria-label="Legal Disbursement Fee"
              name="legalDisbursementFee"
              size="sm" // Set size to "sm"
              thousandSeparator={true}
              value={props.legalDisbursementFee || ""}
              onValueChange={(values) => {
                const { value } = values;
                handleLegalDisbursementFeeChange(value);
              }}
              customInput={Form.Control}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={8}>Stamping Fees</Col>
        <Col md={1}>RM</Col>
        <Col>
          <Form.Group controlId="stampingFee">
            <NumericFormat
              aria-label="Stamping Fee"
              name="stampingFee"
              size="sm" // Set size to "sm"
              thousandSeparator={true}
              value={props.stampingFee || ""}
              onValueChange={(values) => {
                const { value } = values;
                handleStampingFeeChange(value);
              }}
              customInput={Form.Control}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={8}>Miscellaneous Charges</Col>
        <Col md={1}>RM</Col>
        <Col>
          <Form.Group controlId="miscellaneousCharges">
            <NumericFormat
              aria-label="Miscellaneous Charges"
              name="miscellaneousCharges"
              size="sm" // Set size to "sm"
              thousandSeparator={true}
              value={props.miscellaneousCharges || ""}
              onValueChange={(values) => {
                const { value } = values;
                handleMiscellaneousChargesChange(value);
              }}
              customInput={Form.Control}
            />
          </Form.Group>
        </Col>
      </Row>
      <hr className="my-2" />
      <Row className="mb-4">
        <Col md={8}></Col>
        <Col md={1}>RM</Col>
        <Col>
          <Form.Group controlId="totalFee">
            <NumericFormat
              aria-label="Total Fee"
              name="totalFee"
              size="sm" // Set size to "sm"
              thousandSeparator={true}
              value={props.totalFee || 0}
        
              customInput={Form.Control}
              readOnly
            />
           
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default ApprovePage3;
