import React from "react";
import { Button, Dropdown } from "react-bootstrap";
import classNames from "classnames";
import {
  Archive,
  ChevronDown,
  ChevronUp,
  Edit,
  ExternalLink,
  Grid,
  List,
  MoreVertical,
  RefreshCw,
  Server,
  Settings,
  Slash,
  Star,
  Trash2,
  User,
} from "react-feather";
import { connect } from "react-redux";
import { NavLink, useRouteMatch } from "react-router-dom";
import { toggleTopNav } from "../../redux/action/Theme";
import HkTooltip from "../../components/@hk-tooltip/HkTooltip";

const FormHeader = ({ topNavCollapsed, toggleTopNav, show }) => {
  const contactListRoute = useRouteMatch("/apps/contacts/contact-list");

  return (
    <header className="contact-header">
      <h1 className="pg-title">Loan Application Form</h1>
    </header>
  );
};

const mapStateToProps = ({ theme }) => {
  const { topNavCollapsed } = theme;
  return { topNavCollapsed };
};

export default connect(mapStateToProps, { toggleTopNav })(FormHeader);
