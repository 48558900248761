import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { Eye, EyeOff } from "react-feather";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";

// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;

const isAuthenticated = () => {
  //check if the access token is present and not expired
  const accessToken = Cookies.get("accessToken");
  return !!accessToken; // Return true if the accessToken is present
};

const LoginClassic = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated()) {
      history.push("/dashboard");
    }
  }, []);

  const handleLoginSuccess = (accessToken, refreshToken) => {
    Cookies.set("accessToken", accessToken);
    Cookies.set("refreshToken", refreshToken);
    history.push("/dashboard");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setLoading(true);

    fetch(`${backendUri}/api/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.accessToken) {
          //Token is present login is successful
          handleLoginSuccess(res.accessToken, res.refreshToken);
        } else {
          toast.error("Invalid username or password", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error("Login Failed due to :" + err.message);
      });
  };

  return (
    <div className="hk-pg-wrapper pt-0 pb-xl-0 pb-5">
      <div className="hk-pg-body pt-0 pb-xl-0">
        <ToastContainer />
        <Container>
          <Row>
            <Col sm={10} className="position-relative mx-auto">
              <div className="auth-content py-8">
                <Form className="w-100" onSubmit={(e) => handleSubmit(e)}>
                  <Row>
                    <Col lg={5} md={7} sm={10} className="mx-auto">
                      <div className="text-center mb-7">
                        <h2>VESTIUUM</h2>
                      </div>
                      <Card className="card-lg card-border">
                        <Card.Body>
                          <h4 className="mb-4 text-center">
                            Sign in to your account
                          </h4>
                          <Row className="gx-3">
                            <Col as={Form.Group} lg={12} className="mb-3">
                              <div className="form-label-group">
                                <Form.Label>Username</Form.Label>
                              </div>
                              <Form.Control
                                placeholder="Enter username"
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                              />
                            </Col>
                            <Col as={Form.Group} lg={12} className="mb-3">
                              <div className="form-label-group">
                                <Form.Label>Password</Form.Label>
                              </div>
                              <InputGroup className="password-check">
                                <span className="input-affix-wrapper">
                                  <Form.Control
                                    placeholder="Enter password"
                                    value={password}
                                    onChange={(e) =>
                                      setPassword(e.target.value)
                                    }
                                    required
                                    type={showPassword ? "text" : "password"}
                                  />
                                </span>
                              </InputGroup>
                            </Col>
                          </Row>
                          <Button
                            variant="primary"
                            type="submit"
                            className="btn-uppercase btn-block"
                            disabled={loading}
                          >
                            {loading ? (
                              <Spinner animation="border" size="sm" />
                            ) : null}
                            {loading ? null : "Login"}
                          </Button>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default LoginClassic;
