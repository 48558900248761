import React, { useState } from "react";
import SimpleBar from "simplebar-react";
//Images
import avatar2 from "../../assets/dist/img/avatar2.jpg";
import avatar3 from "../../assets/dist/img/avatar3.jpg";
import avatar4 from "../../assets/dist/img/avatar4.jpg";
import avatar10 from "../../assets/dist/img/avatar10.jpg";
import avatar12 from "../../assets/dist/img/avatar12.jpg";
import {
  AlignLeft,
  Bell,
  Calendar,
  CheckSquare,
  Clock,
  CreditCard,
  Inbox,
  Plus,
  Search,
  Settings,
  Tag,
} from "react-feather";
import {
  Button,
  Container,
  Dropdown,
  Form,
  InputGroup,
  Nav,
  Navbar,
} from "react-bootstrap";
import { toggleCollapsedNav } from "../../redux/action/Theme";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
// import CustomInput from './CustomInput';
import classNames from "classnames";
import { motion } from "framer-motion";
import HkBadge from "../../components/@hk-badge/@hk-badge";
import Cookies from "js-cookie";

const TopNav = ({ navCollapsed, toggleCollapsedNav }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const history = useHistory();

  const CloseSearchInput = () => {
    setSearchValue("");
    setShowDropdown(false);
  };

  const logoutButton = () => {
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    history.push("/");
  };

  const pageVariants = {
    initial: {
      opacity: 0,
      y: 10,
    },
    open: {
      opacity: 1,
      y: 0,
    },
    close: {
      opacity: 0,
      y: 10,
    },
  };

  return (
    <Navbar expand="xl" className="hk-navbar navbar-light fixed-top">
      <Container fluid>
        {/* Start Nav */}
        <div className="nav-start-wrap">
          <Button
            variant="flush-dark"
            onClick={() => toggleCollapsedNav(!navCollapsed)}
            className="btn-icon btn-rounded flush-soft-hover navbar-toggle d-xl-none"
          >
            <span className="icon">
              <span className="feather-icon">
                <AlignLeft />
              </span>
            </span>
          </Button>
        </div>
        {/* /Start Nav */}
        {/* End Nav */}
        <div className="nav-end-wrap">
          <Nav className="navbar-nav flex-row">
            <Nav.Item>
              <Button
                onClick={logoutButton}
                className=" btn-rounded flush-soft-hover"
              >
                <span className="btn-text">Logout</span>
              </Button>
            </Nav.Item>
          </Nav>
        </div>
        {/* /End Nav */}
      </Container>
    </Navbar>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed } = theme;
  return { navCollapsed };
};

export default connect(mapStateToProps, { toggleCollapsedNav })(TopNav);
