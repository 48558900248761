import { useState } from "react";
import { Modal, Button, Alert, Row } from "react-bootstrap";
import ApprovePage1 from "./ApproveModalPage/P1_letterDetail";
import ApprovePage2 from "./ApproveModalPage/P2_RepaymentSchedule";
import ApprovePage3 from "./ApproveModalPage/P3_Fees+Charges";
import { toast, ToastContainer } from "react-toastify";
//require("dotenv").config();

// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const ApproveModal = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState("Approved");

  // Page1 Variables
  const [loanAmountApproved, setLoanAmountApproved] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [loanTerm, setLoanTerm] = useState("");
  const [loanTermValue, setLoanTermValue] = useState("");
  const [security, setSecurity] = useState("");
  const [securityValue, setSecurityValue] = useState("");
  const [disburseBefore, setDisburseBefore] = useState("");

  // Page2 Variables
  const [numberOfInstalments, setNumberOfInstalments] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [detail, setDetail] = useState("");

  // Page3 Variables
  const [setupFee, setSetupFee] = useState(0);
  const [legalDisbursementFee, setLegalDisbursementFee] = useState(200);
  const [stampingFee, setStampingFee] = useState(0);
  const [miscellaneousCharges, setMiscellaneousCharges] = useState(0);
  const [totalFee, setTotalFee] = useState(0);

  const handleNext = () => {
    if (
      (currentPage === 1 &&
        (!loanAmountApproved ||
          !interestRate ||
          !loanTerm ||
          !loanTermValue ||
          (props.isSecured && (!security || !securityValue)) ||
          !disburseBefore)) ||
      (currentPage === 2 &&
        (!numberOfInstalments || !startDate || !endDate))
    ) {
      toast.error("Please fill in all the fields before proceed", {
        containerId: "error-container",
      });
    } else {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevious = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch(
      `${backendUri}/api/admin/loan-application/approved/${props.loanApplicationId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.accessToken}`,
        },
        body: JSON.stringify({
          status,
          // Page1
          loanAmountApproved,
          interestRate,
          loanTermValue,
          loanTerm,
          security: props.isSecured === true ? security : null,
          securityValue: props.isSecured === true ? securityValue : 0,
          disburseBefore,
          // Page2
          numberOfInstalments,
          startDate,
          endDate,
          detail,
          // Page3
          setupFee,
          legalDisbursementFee,
          stampingFee,
          miscellaneousCharges,
          totalFee,
        }),
      }
    )
      .then((res) => {
        if (!res.ok) {
          return res.json().then((errorData) => {
            throw new Error(errorData.error);
          });
        }
        return res.json();
      })
      .then((data) => {
        if (data) {
          toast.success(data.message, { containerId: "success-container" });
          props.fetchData(props.accessToken);
          props.handleCloseForm();
        } else {
          toast.error(data.error, { containerId: "error-container" });
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error(`${error.message}`, {
          containerId: "error-container",
        });
      });
  };
  return (
    <Modal show={props.showForm} onHide={props.handleCloseForm}>
      <Modal.Header closeButton>
        <Modal.Title>Create Offer Letter</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {currentPage === 1 && (
          <ApprovePage1
            isSecured={props.isSecured}
            loanAmountApproved={loanAmountApproved}
            interestRate={interestRate}
            loanTerm={loanTerm}
            security={security}
            securityValue={securityValue}
            disburseBefore={disburseBefore}
            loanTermValue={loanTermValue}
            setLoanTermValue={setLoanTermValue}
            setLoanAmountApproved={setLoanAmountApproved}
            setInterestRate={setInterestRate}
            setLoanTerm={setLoanTerm}
            setSecurity={setSecurity}
            setSecurityValue={setSecurityValue}
            setDisburseBefore={setDisburseBefore}
          />
        )}
        {currentPage === 2 && (
          <ApprovePage2
            isSecured={props.isSecured}
            numberOfInstalments={numberOfInstalments}
            startDate={startDate}
            endDate={endDate}
            detail={detail}
            setNumberOfInstalments={setNumberOfInstalments}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setDetail={setDetail}
          />
        )}
        {currentPage === 3 && (
          <ApprovePage3
            loanAmountApproved={loanAmountApproved}
            setupFee={setupFee}
            legalDisbursementFee={legalDisbursementFee}
            stampingFee={stampingFee}
            miscellaneousCharges={miscellaneousCharges}
            totalFee={totalFee}
            setSetupFee={setSetupFee}
            setLegalDisbursementFee={setLegalDisbursementFee}
            setStampingFee={setStampingFee}
            setMiscellaneousCharges={setMiscellaneousCharges}
            setTotalFee={setTotalFee}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        {currentPage === 1 && (
          <Button variant="soft-primary" onClick={handleNext} id="nextBtn">
            Next
          </Button>
        )}
        {currentPage === 2 && (
          <>
            <Button
              variant="soft-secondary"
              onClick={handlePrevious}
              id="previousBtn"
            >
              Previous
            </Button>
            <Button variant="soft-primary" onClick={handleNext} id="nextBtn">
              Next
            </Button>
          </>
        )}
        {currentPage === 3 && (
          <>
            <Button
              variant="soft-secondary"
              onClick={handlePrevious}
              id="previousBtn"
            >
              Previous
            </Button>
            <Button
              variant="soft-primary"
              onClick={handleSubmit}
              id="OfferLetterSubmitBtn"
            >
              Submit
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ApproveModal;
