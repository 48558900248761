import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  InputGroup,
  Button,
  Row,
  Col,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import InputGroupText from "react-bootstrap/esm/InputGroupText";
import { toast } from "react-toastify";
//require("dotenv").config();

// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const ScheduleModal = (props) => {
  // Check data valid
  const [dataCheck, setDataCheck] = useState(false);
  const [scheduleData, setScheduleData] = useState({
    // attestorName: "",
    dateOfFirstRepayment: "",
    dayOfEachRepayment: "",
    expirationMonth: "",
    eachInstalmentRepayment: "",
    finalInstalmentRepayment: "",
    numberOfInstalmentRepayment: "",
  });

  // Refresh Variable
  const [modalKey, setModalKey] = useState(0);
  const [language, setLanguage] = useState("");
  const [schedule, setSchedule] = useState("");

  useEffect(() => {
    if (props.showForm) {
      setDataCheck(false);
      fetchScheduleData(props.loanApplicationId);
    }
  }, [props.showForm]);

  useEffect(() => {
    if (props.showForm) {
      fetchScheduleData(props.loanApplicationId);
    }
  }, [dataCheck]);

  //Get Available Data
  const fetchScheduleData = async (id) => {
    try {
      const response = await fetch(
        `${backendUri}/api/admin/schedule/${props.loanApplicationId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${props.accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();

        if (Object.keys(data.schedule).length > 0) {
          setScheduleData({
            // attestorName: data.schedule.attestorName || "",
            dateOfFirstRepayment: data.schedule.dateOfFirstRepayment || "",
            dayOfEachRepayment: data.schedule.dayOfEachRepayment || "",
            expirationMonth: data.schedule.expirationMonth || "",
            eachInstalmentRepayment:
              data.schedule.eachInstalmentRepayment || "",
            finalInstalmentRepayment:
              data.schedule.finalInstalmentRepayment || "",
            numberOfInstalmentRepayment:
              data.schedule.numberOfInstalmentRepayment || "",
          });

          setDataCheck(true);
        } else {
          setScheduleData({
            // attestorName: "",
            dateOfFirstRepayment: "",
            dayOfEachRepayment: "",
            expirationMonth: "",
            eachInstalmentRepayment: "",
            finalInstalmentRepayment: "",
            numberOfInstalmentRepayment: "",
          });
          setDataCheck(false);
        }
      } else {
        console.log("schedule data is empty");
      }
    } catch (error) {
      console.error("Error fetching loan application data", error.message);
    }
  };
  const handleInputChange = (e) => {
    setScheduleData({
      ...scheduleData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (event) => {
    fetch(`${backendUri}/api/admin/schedule/${props.loanApplicationId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.accessToken}`,
      },
      body: JSON.stringify(scheduleData),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.error) {
          // Handle error, for example, display an error message to the user
          console.error(res.error);
          toast.error(res.error, {
            containerId: "error-container",
          });
        } else {
          setDataCheck(true);

          toast.success(res.message, {
            containerId: "success-container",
          });
          props.fetchData(props.accessToken);
          setModalKey((prevKey) => prevKey + 1);
        }
      })
      .catch((err) => {
        console.error("Fetch API fail");
      });
  };

  // Language Btn
  const handleLanguageBtn = (language) => {
    let schedulePrefix = props.isSecured ? "schedule-K-" : "schedule-J-";
    setSchedule(schedulePrefix);
    setLanguage(language);
    let newScheduleWithLanguage = `${schedulePrefix}${language}`;

    fetch(
      `${backendUri}/api/admin/download/unsign/${newScheduleWithLanguage}/${props.loanApplicationId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
          Authorization: `Bearer ${props.accessToken}`,
        },
      }
    )
      .then(async (res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }

        const contentDisposition = res.headers.get("Content-Disposition");
        const filename = contentDisposition
          .split("filename=")[1]
          .split(";")[0]
          .replace(/"/g, "");
        const blob = await res.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", filename);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        props.fetchData(props.accessToken);
      })
      .catch((error) => {
        console.error("API Fetch Fail", error);
      });
  };

  return (
    <Modal
      show={props.showForm}
      onHide={props.handleCloseForm}
      size="lg"
      key={modalKey}
    >
      <Modal.Header closeButton>
        {props.isSecured ? (
          <Modal.Title>Create Schedule K </Modal.Title>
        ) : (
          <Modal.Title>Create Schedule J </Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>
        {dataCheck ? (
          <Form>
            <h5 className="mb-2">Repayment Details </h5>
            {/* <Row className="mb-2">
              <Form.Group controlId="attestorName" className="mb-1">
                <Form.Label>Attestor Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Attestor Full Name"
                  name="attestorName"
                  value={scheduleData.attestorName || ""}
                  onChange={handleInputChange}
                  readOnly
                />
              </Form.Group>
            </Row> */}

            <Row className="mb-2">
              <Col>
                <Form.Group controlId="dayOfEachRepayment" className="mb-1">
                  <Form.Label>Day Of Each Repayment</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Each Repayment Day"
                    name="dayOfEachRepayment"
                    value={scheduleData.dayOfEachRepayment}
                    onChange={handleInputChange}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="dateOfFirstRepayment" className="mb-1">
                  <Form.Label>Date Of First Repayment</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter First Repayment Date"
                    name="dateOfFirstRepayment"
                    value={scheduleData.dateOfFirstRepayment || ""}
                    onChange={handleInputChange}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={6}>
                <Form.Group controlId="expirationMonth" className="mb-1">
                  <Form.Label>Expiration Month</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Expiration Month"
                    name="expirationMonth"
                    value={scheduleData.expirationMonth}
                    onChange={handleInputChange}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <hr className="my-3" />
            <h5 className="mb-2">Instalment Repayment Details </h5>
            <Row className="mb-2">
              <Form.Group controlId="eachInstalmentRepayment" className="mb-1">
                <Form.Label>Amount of Each Instalment Repayment</Form.Label>
                <InputGroup>
                  <InputGroup.Text>RM</InputGroup.Text>
                  <NumericFormat
                    placeholder="Enter Amount of Each Instalment Repayment"
                    aria-label="Each Instalment Repayment"
                    name="eachInstalmentRepayment"
                    thousandSeparator={true}
                    value={scheduleData.eachInstalmentRepayment || ""}
                    onValueChange={(values) => {
                      const { value } = values;
                      handleInputChange({
                        target: {
                          name: "eachInstalmentRepayment",
                          value: value,
                        },
                      });
                    }}
                    customInput={Form.Control}
                    readOnly
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group controlId="finalInstalmentRepayment" className="mb-1">
                <Form.Label>Amount of Final Instalment Repayment</Form.Label>
                <InputGroup>
                  <InputGroup.Text>RM</InputGroup.Text>

                  <NumericFormat
                    placeholder="Enter Amount of Final Instalment Repayment"
                    aria-label="Each Instalment Repayment"
                    name="finalInstalmentRepayment"
                    thousandSeparator={true}
                    value={scheduleData.finalInstalmentRepayment || ""}
                    onValueChange={(values) => {
                      const { value } = values;
                      handleInputChange({
                        target: {
                          name: "finalInstalmentRepayment",
                          value: value,
                        },
                      });
                    }}
                    customInput={Form.Control}
                    readOnly
                  />
                  
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group
                controlId="numberOfInstalmentRepayment"
                className="mb-1"
              >
                <Form.Label>Number of Instalment Repayment</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="number"
                    name="numberOfInstalmentRepayment"
                    value={scheduleData.numberOfInstalmentRepayment}
                    onChange={handleInputChange}
                    readOnly
                  />
                </InputGroup>
              </Form.Group>
            </Row>
          </Form>
        ) : (
          <Form>
            <h5 className="mb-2">Repayment Details </h5>
            {/* <Row className="mb-2">
              <Form.Group controlId="attestorName" className="mb-1">
                <Form.Label>Attestor Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Attestor Full Name"
                  name="attestorName"
                  value={scheduleData.attestorName || ""}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Row> */}

            <Row className="mb-2">
              <Col>
                <Form.Group controlId="dayOfEachRepayment" className="mb-1">
                  <Form.Label>Day Of Each Repayment</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Each Repayment Day"
                    name="dayOfEachRepayment"
                    value={scheduleData.dayOfEachRepayment}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="dateOfFirstRepayment" className="mb-1">
                  <Form.Label>Date Of First Repayment</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Enter First Repayment Date"
                    name="dateOfFirstRepayment"
                    value={scheduleData.dateOfFirstRepayment || ""}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={6}>
                <Form.Group controlId="expirationMonth" className="mb-1">
                  <Form.Label>Expiration Month</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Expiration Month"
                    name="expirationMonth"
                    value={scheduleData.expirationMonth}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <hr className="my-3" />
            <h5 className="mb-2">Instalment Repayment Details </h5>
            <Row className="mb-2">
              <Form.Group controlId="eachInstalmentRepayment" className="mb-1">
                <Form.Label>Amount of Each Instalment Repayment</Form.Label>
                <InputGroup>
                  <InputGroup.Text>RM</InputGroup.Text>
                  <NumericFormat
                    placeholder="Enter Amount of Each Instalment Repayment"
                    aria-label="Each Instalment Repayment"
                    name="eachInstalmentRepayment"
                    thousandSeparator={true}
                    value={scheduleData.eachInstalmentRepayment || ""}
                    onValueChange={(values) => {
                      const { value } = values;
                      handleInputChange({
                        target: {
                          name: "eachInstalmentRepayment",
                          value: value,
                        },
                      });
                    }}
                    customInput={Form.Control}
                    required
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group controlId="finalInstalmentRepayment" className="mb-1">
                <Form.Label>Amount of Final Instalment Repayment</Form.Label>
                <InputGroup>
                  <InputGroup.Text>RM</InputGroup.Text>

                  <NumericFormat
                    placeholder="Enter Amount of Final Instalment Repayment"
                    aria-label="Each Instalment Repayment"
                    name="finalInstalmentRepayment"
                    thousandSeparator={true}
                    value={scheduleData.finalInstalmentRepayment || ""}
                    onValueChange={(values) => {
                      const { value } = values;
                      handleInputChange({
                        target: {
                          name: "finalInstalmentRepayment",
                          value: value,
                        },
                      });
                    }}
                    customInput={Form.Control}
                    required
                  />
                  
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group
                controlId="numberOfInstalmentRepayment"
                className="mb-1"
              >
                <Form.Label>Number of Instalment Repayment</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="number"
                    placeholder="Enter Number of Instalment Repayment"
                    name="numberOfInstalmentRepayment"
                    value={scheduleData.numberOfInstalmentRepayment}
                    onChange={handleInputChange}
                    required
                  />
                </InputGroup>
              </Form.Group>
            </Row>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        {dataCheck ? (
          <Dropdown drop="up">
            <Dropdown.Toggle variant="primary" id="downloadScheduleBtn">
              Download
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  handleLanguageBtn("ENG");
                }}
                id="engBtn"
              >
                English
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  handleLanguageBtn("BM");
                }}
                id="bmBtn"
              >
                Bahasa Melayu
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : null}

        {dataCheck ? null : (
          <Button
            variant="soft-primary"
            id="scheduleSubmitBtn"
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        )}
        <Button
          variant="soft-light"
          id="closeBtn"
          onClick={props.handleCloseForm}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ScheduleModal;
