import LoanApplicationView from "../views/LoanApplication";
import Application from "../views/ApplicationForm";
import SuccessPage from "../views/ApplicationForm/SuccessPage";
import ClientView from "../views/Client";
import ClientCompanyView from "../views/ClientCompany";
//Auth
import LoginClassic from "../views/Authentication/LogIn/LoginClassic";
import CreateLoanApplication from "../views/ApplicationForm/CreateLoanApplication"

export const routes = [
  { path: "dashboard", exact: true, component: LoanApplicationView },
  { path: "client", component: ClientView },
  { path: "clientCompany", component: ClientCompanyView },
];

export const clientRoutes = [
  { path: "/application_form/:token", component: Application },
  { path: "/success", component: SuccessPage },
  { path: "/loan-application", component: CreateLoanApplication}
];

export const authRoutes = [
  { path: "/login-classic", exact: true, component: LoginClassic },
];
