import * as Icons from "tabler-icons-react";
import HkBadge from "../../components/@hk-badge/@hk-badge";

export const SidebarMenu = [
  {
    group: "",
    contents: [
      {
        name: "Application Form",
        icon: <Icons.FileInvoice />,
        path: "/dashboard",
      },
      {
        name: "Client Management",
        icon: <Icons.Users />,
        path: "/client",
      },
      {
        name: "Company Management",
        icon: <Icons.BuildingArch />,
        path: "/clientCompany",
      },
    ],
  },
];
