import React, { useState, useEffect } from "react";
import { Col, Form, Row, InputGroup } from "react-bootstrap";
import { Country, State } from "country-state-city";
import { NumericFormat } from "react-number-format";

const SectionA = ({
  isPersonalLoan,
  handleInputChange,
  loanDetails,
  companyDetails,
  isinvalid,
  status
}) => {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [purpose, setPurpose] = useState("");
  const [tenure, setTenure] = useState("");

  useEffect(() => {
    setSelectedCountry(companyDetails.companyCountry);
  }, [companyDetails.companyCountry]);

  useEffect(() => {
    if (loanDetails.purpose === undefined) {
      setPurpose("");
    } else if (
      ![
        "Business funding",
        "Short-term cash flow",
        "Medical",
        "Personal Use",
        "",
      ].includes(loanDetails.purpose)
    ) {
      setPurpose("Others");
    } else {
      setPurpose(loanDetails.purpose);
    }
  }, [loanDetails.purpose]);

  useEffect(() => {
    if (loanDetails.tenure === undefined) {
      setTenure("");
    } else if (
      ![
        "3 months",
        "6 months",
        "12 months",
        "24 months",
        "36 months",
        "",
      ].includes(loanDetails.tenure)
    ) {
      setTenure("Others");
    } else {
      setTenure(loanDetails.tenure);
    }
  }, [loanDetails.tenure]);

  useEffect(() => {
    const allCountries = Country.getAllCountries();
    setCountries(allCountries);
    if (!companyDetails.companyCountry) {
      const malaysia = allCountries.find(
        (country) => country.name === "Malaysia"
      );
      if (malaysia) {
        setSelectedCountry(malaysia.isoCode);
      }
    }
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      const statesForCountry = State.getStatesOfCountry(selectedCountry);
      setStates(statesForCountry);
      setSelectedState("");
    }
  }, [selectedCountry]);

  const handleCountryChange = (event) => {
    const newCountry = event.target.value;
    setSelectedCountry(newCountry);
    handleInputChange(event);
  };

  const handleStateChange = (event) => {
    const newState = event.target.value;
    setSelectedState(newState);
    handleInputChange(event);
  };

  return (
    <Form.Group controlId="formStep1">
      <Row>
        <h5 className="mb-3">Type of Loan</h5>
        <Form.Group as={Col} className="mb-3">
          <Col sm={10}>
            <Form.Check
              type="radio"
              label="Personal Loan"
              name="isPersonal"
              id="isPersonal1"
              value="true"
              defaultChecked={isPersonalLoan}
              disabled={!isPersonalLoan || status === null || status === undefined || (status && parseInt(status) >= 9)}
            />
            <Form.Check
              type="radio"
              label="Corporate Loan"
              name="isPersonal"
              id="isPersonal2"
              value="false"
              defaultChecked={!isPersonalLoan}
              disabled={isPersonalLoan || status === null || status === undefined || (status && parseInt(status) >= 9)}
            />
          </Col>
        </Form.Group>
      </Row>
      {isPersonalLoan === false && (
        <Form.Group className="mb-5">
          <h5>Company Details</h5>
          <Form.Group className="mb-3" controlId="companyName">
            <Form.Label>Company Name*</Form.Label>
            <Form.Control
              name="companyName"
              onChange={handleInputChange}
              value={companyDetails.companyName || ""}
              isInvalid={isinvalid && !companyDetails.companyName}
              disabled={status === null || status === undefined || (status && parseInt(status) >= 9)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a company name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="companyAddressLine1">
            <Form.Label>Address Line 1*</Form.Label>
            <Form.Control
              name="companyAddressLine1"
              placeholder="1234 Main St"
              onChange={handleInputChange}
              value={companyDetails.companyAddressLine1 || ""}
              isInvalid={isinvalid && !companyDetails.companyAddressLine1}
              disabled={status === null || status === undefined || (status && parseInt(status) >= 9)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide an address.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="companyAddressLine2">
            <Form.Label>Address Line 2</Form.Label>
            <Form.Control
              name="companyAddressLine2"
              placeholder="Apartment, studio, or floor"
              onChange={handleInputChange}
              value={companyDetails.companyAddressLine2 || ""}
              disabled={status === null || status === undefined || (status && parseInt(status) >= 9)}
            />
          </Form.Group>
          <Row className="mb-3">
            <Form.Group
              as={Col}
              sm={12}
              md={3}
              controlId="companyCity"
              className="mb-3 mb-md-0"
            >
              <Form.Label>Area*</Form.Label>
              <Form.Control
                name="companyCity"
                onChange={handleInputChange}
                value={companyDetails.companyCity || ""}
                isInvalid={isinvalid && !companyDetails.companyCity}
                disabled={status === null || status === undefined || (status && parseInt(status) >= 9)}
              />
              <Form.Control.Feedback type="invalid">
                Please provide an area.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              as={Col}
              sm={12}
              md={3}
              controlId="companyZipCode"
              className="mb-3 mb-md-0"
            >
              <Form.Label>Postal Code*</Form.Label>
              <NumericFormat
                name="companyZipCode"
                value={companyDetails.companyZipCode || ""}
                onValueChange={(values) => {
                  const { value } = values;
                  handleInputChange({
                    target: {
                      name: "companyZipCode",
                      value: value,
                    },
                  });
                }}
                customInput={Form.Control}
                allowNegative={false}
                decimalScale={0}
                isInvalid={isinvalid && !companyDetails.companyZipCode}
                disabled={status === null || status === undefined || (status && parseInt(status) >= 9)}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a postal code.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              as={Col}
              sm={12}
              md={3}
              controlId="companyState"
              className="mb-3 mb-md-0"
            >
              <Form.Label>State*</Form.Label>
              <Form.Select
                name="companyState"
                onChange={handleStateChange}
                value={companyDetails.companyState}
                isInvalid={isinvalid && !companyDetails.companyState}
                disabled={status === null || status === undefined || (status && parseInt(status) >= 9)}
              >
                <option value="">Choose...</option>
                {states.map((state) => (
                  <option key={state.isoCode} value={state.isoCode}>
                    {state.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Please select a state.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              as={Col}
              sm={12}
              md={3}
              controlId="companyCountry"
              className="mb-3 mb-md-0"
            >
              <Form.Label>Country*</Form.Label>
              <Form.Select
                name="companyCountry"
                onChange={handleCountryChange}
                value={companyDetails.companyCountry || selectedCountry}
                disabled
              >
                <option value="">Choose...</option>
                {countries.map((country) => (
                  <option key={country.isoCode} value={country.isoCode}>
                    {country.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>
        </Form.Group>
      )}
      <h5>Loan Application Details</h5>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="isSecured">
          <Form.Label>Loan Preferences*</Form.Label>
          <Form.Select
            name="isSecured"
            value={loanDetails.isSecured}
            onChange={handleInputChange}
            isInvalid={isinvalid && !loanDetails.isSecured}
            disabled={status === null || status === undefined ||(status && parseInt(status) >= 9)}
          >
            <option value="">Choose...</option>
            <option value="true">Secured Loan</option>
            <option value="false">Unsecured Loan</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please select a loan preference.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="loanAmount" sm={12} md={4}>
          <Form.Label>Loan Amount*</Form.Label>
          <InputGroup className="mb-3">
            <InputGroup.Text>RM</InputGroup.Text>
            <NumericFormat
              aria-label="Loan Amount"
              name="loanAmount"
              thousandSeparator={true}
              value={loanDetails.loanAmount || ""}
              onValueChange={(values) => {
                const { value } = values;
                handleInputChange({
                  target: {
                    name: "loanAmount",
                    value: value,
                  },
                });
              }}
              customInput={Form.Control}
              isInvalid={isinvalid && !loanDetails.loanAmount}
              allowNegative={false}
              disabled={status === null || status === undefined || (status && parseInt(status) >= 9)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid loan amount.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} controlId="tenure" sm={12} md={4} className="mb-3">
          <Form.Label>Tenure*</Form.Label>
          <Form.Select
            name="tenure"
            value={tenure}
            onChange={handleInputChange}
            isInvalid={isinvalid && !loanDetails.tenure}
            disabled={status === null || status === undefined || (status && parseInt(status) >= 9)}
          >
            <option value="">Choose...</option>
            <option value="3 months">3 months</option>
            <option value="6 months">6 months</option>
            <option value="12 months">12 months</option>
            <option value="24 months">24 months</option>
            <option value="36 months">36 months</option>
            <option value="Others">Others</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please select a tenure.
          </Form.Control.Feedback>
        </Form.Group>
        {tenure === "Others" && (
          <Form.Group
            as={Col}
            controlId="otherTenure"
            sm={12}
            md={4}
            className="mb-3"
          >
            <Form.Label>Specify Other Tenure*</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter other tenure"
              name="otherTenure"
              value={loanDetails.tenure || ""}
              onChange={handleInputChange}
              disabled={status === null || status === undefined || (status && parseInt(status) >= 9)}
            />
          </Form.Group>
        )}
        <Form.Group
          as={Col}
          controlId="purposeOfLoan"
          sm={12}
          md={4}
          className="mb-3"
        >
          <Form.Label>Purpose of Loan*</Form.Label>
          <Form.Select
            name="purpose"
            value={purpose}
            onChange={handleInputChange}
            isInvalid={isinvalid && !loanDetails.purpose}
            disabled={status === null || status === undefined || (status && parseInt(status) >= 9)}
          >
            <option value="">Choose...</option>
            <option value="Business funding">Business funding</option>
            <option value="Short-term cash flow">Short-term cash flow</option>
            <option value="Medical">Medical</option>
            <option value="Personal Use">Personal Use</option>
            <option value="Others">Others</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please select a purpose of loan.
          </Form.Control.Feedback>
        </Form.Group>
        {purpose === "Others" && (
          <Form.Group
            as={Col}
            controlId="otherPurpose"
            sm={12}
            md={4}
            className="mb-3 mb-md-0"
          >
            <Form.Label>Specify Other Purpose</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter other purpose"
              name="otherPurpose"
              value={loanDetails.purpose || ""}
              onChange={handleInputChange}
              isInvalid={isinvalid && !loanDetails.purpose}
              disabled={status === null || status === undefined || (status && parseInt(status) >= 9)}
            />
            <Form.Control.Feedback type="invalid">
              Please specify a purpose of loan.
            </Form.Control.Feedback>
          </Form.Group>
        )}
      </Row>
    </Form.Group>
  );
};

export default SectionA;
